import { useLanguageStore } from '../stores';
import { Button, BackButton } from '../components';
import { useForceSession } from '../util/hooks';

export default function KycManualException() {
	useForceSession();
	const { t } = useLanguageStore();

	const emailSupport = () => {
		window.location.href = 'mailto:support@manifestfinancial.com';
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t(`There was an issue verifying your personal info.`)}</h2>
				<p>{t(`Please contact support@manifestfinancial.com for assistance.`)}</p>
				<Button onClick={emailSupport} style={{ width: 240, marginTop: 60 }}>
					{t('Email Support')}
				</Button>
			</div>
		</div>
	);
}
