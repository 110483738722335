import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const TextMaskDate = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="00-00-0000"
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export const TextMaskSSN = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="000-00-0000"
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export const TextMaskState = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="aa"
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value: value.toUpperCase() } })}
			overwrite
		/>
	);
});

export const TextMaskZipCode = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="00000"
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export const TextMaskCode = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="000000"
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export const TextMaskMobileNumber = forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="#00-000-0000"
			definitions={{
				'#': /[1-9]/,
			}}
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});
