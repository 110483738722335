import create from 'zustand';
import API from '../util/API';
import alloy from '@alloyidentity/web-sdk';
import { sleep } from '../util/sleep';

export default create((set) => ({
	stepUpConnected: false,
	stepUpBlocked: false,
	resetParams: () => {
		set({ stepUpConnected: false, stepUpBlocked: false });
	},
	initStepUp: async () => {
		try {
			const stepUpInitParams = await API.get('/banking/apply/kyc-step-up-config');
			const winner = await Promise.race([alloy.init({ ...stepUpInitParams, key: process.env.REACT_APP_IOVATION_KEY, production: process.env.REACT_APP_BUILD_ENV === 'production' }), sleep(3000)]);

			const successful = winner !== 'sleep';

			set({ stepUpBlocked: !successful, stepUpConnected: successful });
		} catch (e) {
			console.error('An error occurred initializing Step Up', e);
			set({ stepUpConnected: false });
		}
	},
	onOpen: (callback) => {
		alloy.open(callback);
	},
	onClose: () => {
        alloy.close();
		set({ stepUpConnected: false });
	},
}));
