import { useEffect } from 'react';
import { useLanguageStore, useStepUpStore, useAuthStore, useConnectStore } from '../stores';
import { AlertBox, Button, BackButton } from '../components';
import { useForceSession } from '../util/hooks';
import { useNavigate } from 'react-router-dom';

const listItems = [{ value: 'Make sure your adblocker is disabled.' }, { value: 'Check your network connection.'}];
const headline = 'There was an error loading the identity verification service.';

export default function KycStepUp() {
	useForceSession();

	const { t } = useLanguageStore();
	const { stepUpConnected, stepUpBlocked, resetParams, initStepUp, onOpen, onClose } = useStepUpStore();
	const { markHasFailedNerveKyc } = useConnectStore();
	const { profile, refresh } = useAuthStore();

	const navigate = useNavigate();

	useEffect(() => {
		initStepUp();
		return () => onClose();
	}, []);

	useEffect(() => {
		if (profile) {
			const { businessOwnership, kycStatus } = profile;
			if (kycStatus === 'PENDING_STEP_UP') {
				navigate('../identity-verification-step-up');
			} else if (kycStatus === 'EXCEPTION' || kycStatus === 'INVALID_DATA' || kycStatus === 'PENDING') {
				navigate('../identity-verification-exception');
			} else if (kycStatus === 'MANUAL_REVIEW') {
				navigate('../identity-verification-manual-review');
			} else if (kycStatus === 'DENIED') {
				markHasFailedNerveKyc();
				navigate('../identity-verification-denied');
			} else if (businessOwnership?.length > 0) {
				navigate('../download');
			}
		}
	}, [profile]);

	const callback = (data) => {
		console.log(data);
		refresh();
	};

	const handleStepUpFlow = () => {
		stepUpConnected && onOpen(callback);
	};

	const handleRetry = () => {
		resetParams();
		initStepUp();
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t(`We're going to need a little more information to verify your identity.`)}</h2>
				<p>
					{t(
						`Sorry for the inconvenience, but we need to get some supporting documents from you to verify your identity.`,
					)}
				</p>
				{
					!stepUpBlocked ? <Button onClick={handleStepUpFlow} style={{ width: 240, marginTop: 60, cursor: 'pointer' }}>
								{t(stepUpConnected ? 'Continue' : 'Loading...')}
							</Button>
						: <>
							<AlertBox headline={headline} listItems={listItems}/>
							<Button style={{ width: 240, marginTop: 30, cursor: 'pointer' }} onClick={handleRetry}>{t('Retry')}</Button>
						  </>
				}
			</div>
		</div>
	);
}
