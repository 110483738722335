import { useLanguageStore, useConnectStore } from '../stores';
import { Button, BackButton } from '../components';
import { useForceSession } from '../util/hooks';

export default function KycDenied() {
	useForceSession();
	const { t } = useLanguageStore();
	const { enterpriseName, offerAchOption, offerPaypalOption, returnToEnterprise, canReturnToEnterprise } =
		useConnectStore();
	const hasOtherOptions = offerAchOption || offerPaypalOption;

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t('We were unable to verify your personal info.')}</h2>
				<p>
					{hasOtherOptions
						? t(`SecondChanceAch`, { enterpriseName })
						: t(
								'Unfortunately, at this time, we are not able to open a Manifest account for you. Thank you!',
						  )}
				</p>
				{hasOtherOptions && (
					<Button to={'../options'} style={{ width: 260, marginTop: 60 }}>
						{t('Use Another Method')}
					</Button>
				)}
				{!hasOtherOptions && canReturnToEnterprise() && (
					<Button small theme="tertiary" onClick={returnToEnterprise} style={{ width: 240, marginTop: 60 }}>
						{t('BackTo', { enterpriseName })}
					</Button>
				)}
			</div>
		</div>
	);
}
