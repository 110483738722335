import { useEffect } from 'react';
import { useLanguageStore, useAuthStore, useConnectStore } from '../stores';
import Loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useForceSession } from '../util/hooks';

export default function Opening() {
	useForceSession();
	const { t } = useLanguageStore();
	const { refresh, profile } = useAuthStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (profile) {
			const { businessOwnership, kycStatus } = profile;
			if (kycStatus === 'PENDING_STEP_UP') {
				navigate('../identity-verification-step-up');
			} else if (kycStatus === 'EXCEPTION' || kycStatus === 'INVALID_DATA' || kycStatus === 'PENDING') {
				navigate('../identity-verification-exception');
			} else if (kycStatus === 'MANUAL_REVIEW') {
				navigate('../identity-verification-manual-review');
			} else if (kycStatus === 'DENIED') {
				navigate('../identity-verification-denied');
			} else if (businessOwnership?.length > 0) {
				const businessProfile = businessOwnership[0].businessProfile;
				if (businessProfile.checkingAccountId) {
					navigate('../download');
				} else {
					setTimeout(refresh, 2000);
				}
			} else {
				setTimeout(refresh, 2000);
			}
		}
	}, [profile]);

	return (
		<div className="page-container centered">
			<div className="page">
				<Loader
					type="Oval"
					color="#dee3eb"
					height={100}
					width={100}
					style={{ alignSelf: 'center', marginTop: '100px', marginBottom: '20px' }}
				/>
				<p className="display-linebreak">{t('Opening account— This will only take around 20 seconds.')}</p>
			</div>
		</div>
	);
}
