import { useLanguageStore } from '../stores';
import { Button, BackButton } from '../components';
import { useForceSession, usePhyllo } from '../util/hooks';

export default function Phyllo() {
	useForceSession();
	const { t } = useLanguageStore();
	const { loading, launch } = usePhyllo();

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t(`Connect your creator profiles so we can verify you.`)}</h2>
				<p>
					{t(
						'Manifest is currently invite only — but you can skip the line. Link your social profiles to see if you qualify for an automatic invite.',
					)}
				</p>
				<Button loading={loading} onClick={launch} style={{ width: 240, marginTop: 60 }}>
					{t('Connect Profiles')}
				</Button>
			</div>
		</div>
	);
}
