import { useState } from 'react';
import { useLanguageStore, useConnectStore } from '../stores';
import { Button, BackButton } from '../components';
import { useNavigate } from 'react-router-dom';

export default function Disconnect() {
	const { t } = useLanguageStore();
	const [submitting, setSubmitting] = useState(false);
	const { enterpriseName, disconnect, accountConnection } = useConnectStore();
	const navigate = useNavigate();

	const onSubmit = async () => {
		setSubmitting(true);

		try {
			await disconnect();
			navigate('../complete');
		} catch (e) {
			setSubmitting(false);
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('DisconnectHeadline', { enterpriseName })}</h2>
				{accountConnection.type === 'direct' && (
					<div className="box option-box">{t('DisconnectBodyManifest', { enterpriseName })}</div>
				)}
				{accountConnection.type === 'ach' && (
					<div className="box option-box">{t('DisconnectBodyAch', { enterpriseName })}</div>
				)}

				<Button onClick={onSubmit} enabled={!submitting} theme="tertiary" style={{ width: 240, marginTop: 60 }}>
					{t('Disconnect')}
				</Button>
			</div>
		</div>
	);
}
