import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import Invite from './routes/invite';
import Wrapper from './Wrapper';
import Intro from './routes/intro';
import Options from './routes/options';
import Login from './routes/login';
import SignUp from './routes/signup';
import BankInfo from './routes/bankinfo';
import PaypalInfo from './routes/paypalinfo';
import MFA from './routes/mfa';
import NotFound from './routes/notfound';
import Connect from './routes/connect';
import Disconnect from './routes/disconnect';
import Application from './routes/application';
import Opening from './routes/opening';
import Complete from './routes/complete';
import ForgotPassword from './routes/forgotpassword';
import ResetPassword from './routes/resetpassword';
import Phyllo from './routes/phyllo';
import Waitlist from './routes/waitlist';
import PayoutDetails from './routes/payoutdetails';
import Download from './routes/download';
import KycDenied from './routes/kyc-denied';
import KycException from './routes/kyc-exception';
import KycManualReview from './routes/kyc-manualreview';
import KycStepUp from './routes/kyc-stepup';

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
	datadogRum.init({
		applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
		clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: process.env.REACT_APP_DATADOG_SITE,
		service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
		env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	});
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#657C9D',
		},
		secondary: {
			main: '#8DA4C4',
		},
		error: {
			main: '#CC0000',
		},
		warning: {
			main: '#FFAC50',
		},
		success: {
			main: '#7AB53F',
		},
		info: {
			main: '#02B8D8',
		},
	},
});

const rootElement = document.getElementById('root');
render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<NotFound />} />
				<Route path="/" element={<Wrapper />}>
					<Route index element={<Navigate to="/en" />} />
					<Route path={'/:language'} element={<App />}>
						<Route path="invite/:inviteToken" element={<Invite />} />
						<Route path="intro/:token" element={<Intro />} />
						<Route path="options" element={<Options />} />
						<Route path="payouts/:payoutId" element={<PayoutDetails />} />
						<Route path="sign-in" element={<Login />} />
						<Route path="forgot-password" element={<ForgotPassword />} />
						<Route path="reset-password" element={<ResetPassword />} />
						<Route path="sign-up" element={<SignUp />} />
						<Route path="verify-mobile" element={<MFA />} />
						<Route path="link-profiles" element={<Phyllo />} />
						<Route path="open-now" element={<Application />} />
						<Route path="bank-info" element={<BankInfo />} />
						<Route path="paypal-venmo-info" element={<PaypalInfo />} />
						<Route path="connect" element={<Connect />} />
						<Route path="disconnect" element={<Disconnect />} />
						<Route path="opening-account" element={<Opening />} />
						<Route path="identity-verification-denied" element={<KycDenied />} />
						<Route path="identity-verification-exception" element={<KycException />} />
						<Route path="identity-verification-manual-review" element={<KycManualReview />} />
						<Route path="identity-verification-step-up" element={<KycStepUp />} />
						<Route path="creator-verification-needed" element={<Waitlist />} />
						<Route path="complete" element={<Complete />} />
						<Route path="download" element={<Download />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	</ThemeProvider>,
	rootElement,
);
