import create from 'zustand';
import API from '../util/API';
import { persist } from 'zustand/middleware';
import produce from 'immer';

const useConnectStore = create(
	persist(
		(set, get) => ({
			token: null,
			setToken: async token => {
				const { token: oldToken, loadSession } = get();
				if (token !== oldToken) {
					set({ token, cachedSession: null, hasLoadedSession: false });
					await loadSession();
				}
			},
			hasLoadedSession: false,
			enterpriseName: null,
			enterpriseAvatar: null,
			whiteLabelApp: null,
			appLink: null,
			appName: null,
			userAddendum: null,
			email: null,
			firstName: null,
			permissions: [],
			returnUrl: null,
			useCase: 'payout',
			offerAchOption: true,
			offerPaypalOption: true,
			cachedSession: null,
			error: false,
			secret: null,
			affiliateCode: null,
			requestingNewPermissions: false,
			hasOnlyPayoutPermission: false,
			requirePhyllo: false,
			hasFailedNerveKyc: false,
			isEmbedded: () => window.location !== window.parent.location,
			canReturnToEnterprise: () => {
				const { returnUrl, isEmbedded } = get();
				if (window.ReactNativeWebView) {
					return true;
				} else if (window.connectMessageHandler && window.connectMessageHandler.postMessage) {
					return true;
				} else if (
					window.webkit &&
					window.webkit.messageHandlers.connectMessageHandler &&
					window.webkit.messageHandlers.connectMessageHandler.postMessage
				) {
					return true;
				} else if (isEmbedded()) {
					return true;
				} else {
					return !!returnUrl;
				}
			},
			returnToEnterprise: () => {
				const { returnUrl, isEmbedded } = get();
				const message = 'connect_exit';
				set({ token: null, cachedSession: null });
				if (window.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(message);
				} else if (window.connectMessageHandler && window.connectMessageHandler.postMessage) {
					window.connectMessageHandler.postMessage(message);
				} else if (
					window.webkit &&
					window.webkit.messageHandlers.connectMessageHandler &&
					window.webkit.messageHandlers.connectMessageHandler.postMessage
				) {
					window.webkit.messageHandlers.connectMessageHandler.postMessage(message);
				} else if (isEmbedded()) {
					window.parent.postMessage(message, '*');
				} else if (returnUrl) {
					window.location.assign(returnUrl);
				}
			},
			connectBusinessProfileId: async businessProfileId => {
				const { token, secret } = get();
				try {
					await API.post(`/banking/connect/${token}/business/${businessProfileId}`, { secret });
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			connectAch: async ({
				institutionName,
				nameOnAccount,
				accountNumber,
				accountType,
				routingNumber,
				firstName,
				lastName,
				streetLine1,
				streetLine2,
				city,
				state,
				postalCode,
				tin,
			}) => {
				const { token, secret } = get();
				try {
					await API.post(`/banking/connect/${token}/ach`, {
						institutionName,
						nameOnAccount,
						accountNumber,
						accountType,
						routingNumber,
						firstName,
						lastName,
						streetLine1,
						streetLine2,
						city,
						state,
						postalCode,
						tin,
						secret,
					});
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			connectPaypal: async ({ externalAddress }) => {
				const { token, secret } = get();
				try {
					await API.post(`/banking/connect/${token}/paypal`, {
						externalAddress,
						secret,
					});
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			disconnect: async () => {
				const { token, secret } = get();
				try {
					await API.post(`/banking/connect/${token}/deactivate`, { secret });
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			payouts: null,
			loadPayouts: async () => {
				const { token, secret } = get();
				try {
					set({ payouts: null });
					const { payouts } = await API.post(`/banking/connect/${token}/payout`, { secret });
					set({ payouts });
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			acceptAdvanceOffer: async ({ payoutId, offerKey }) => {
				const { token, secret, loadPayouts } = get();
				try {
					await API.post(`/banking/connect/${token}/payout/${payoutId}/advance/${offerKey}`, { secret });
					loadPayouts();
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			markHasFailedNerveKyc: async () => {
				const { token, secret, cachedSession } = get();
				try {
					await API.post(`/banking/connect/${token}`, { secret, hasFailedNerveKyc: true });
					const newCachedSession = produce(cachedSession, draft => {
						if (draft?.accountConnection) {
							draft.accountConnection.hasFailedNerveKyc = true;
						}
					});
					set({ hasFailedNerveKyc: true, cachedSession: newCachedSession });
				} catch (e) {
					set({ error: true });
					throw e;
				}
			},
			loadSession: async () => {
				const { token, cachedSession } = get();

				if (!token) return;

				let session;

				if (cachedSession) {
					session = cachedSession;
				} else {
					try {
						session = await API.get(`/banking/connect/${token}`);
					} catch (e) {
						set({ error: true });
						return;
					}
				}

				const {
					accountConnection,
					accountConnection: { email, firstName, hasFailedNerveKyc },
					enterprise: {
						name: enterpriseName,
						avatar: enterpriseAvatar,
						affiliateCode,
						requirePhylloInConnect: requirePhyllo,
						whiteLabelApp,
						appLink,
						appName,
						userAddendum,
					},
					connectLink: {
						permissions,
						returnUrl,
						offerAchOption,
						offerPaypalOption,
						useCase,
						secret,
						expiresAt,
					},
				} = session;

				if (new Date() > new Date(expiresAt)) {
					set({ error: true, hasLoadedSession: true });
					// throw new Error('Your link has expired. Please start over.');
				}

				const requestingNewPermissions = !permissions.every(item =>
					accountConnection.permissions.some(permission => permission.name === item),
				);

				const hasOnlyPayoutPermission =
					accountConnection.permissions.length === 1 &&
					accountConnection.permissions[0].name === 'payment.credit';

				set({
					hasLoadedSession: true,
					enterpriseName,
					enterpriseAvatar,
					requirePhyllo,
					whiteLabelApp,
					appLink,
					appName,
					userAddendum,
					email,
					firstName,
					permissions,
					returnUrl,
					offerAchOption,
					offerPaypalOption,
					useCase,
					cachedSession: session,
					secret,
					affiliateCode,
					accountConnection,
					requestingNewPermissions,
					hasOnlyPayoutPermission,
					hasFailedNerveKyc,
				});
			},
		}),
		{
			name: 'connect',
			partialize: ({ token, cachedSession }) => ({ token, cachedSession }),
			getStorage: () => sessionStorage,
		},
	),
);

useConnectStore.getState().loadSession();

export default useConnectStore;
