import { Button, BackButton } from '../components';
import { Link } from 'react-router-dom';
import { useLanguageStore, useConnectStore } from '../stores';
import useQueryString from 'use-query-string';
import moment from 'moment';
import caretRight from '../assets/caretRight.svg';
import frontMeMetaIcon from '../assets/frontMeMetaIcon.svg';
import frontMeOfferMetaIcon from '../assets/frontMeOfferMetaIcon.svg';
import { useEffect } from 'react';
import { fromCents } from '../util/string';

export default function Options() {
	const { t } = useLanguageStore();
	const {
		offerAchOption,
		offerPaypalOption,
		useCase,
		enterpriseName,
		appName,
		accountConnection,
		hasOnlyPayoutPermission,
		hasFailedNerveKyc,
		payouts,
		loadPayouts,
	} = useConnectStore();
	const [{ upgrade }] = useQueryString(window.location);
	const upgrading = upgrade == 1;

	const buttonStyle = { marginTop: 50, marginBottom: 8, padding: '0 30px' };
	const numberOfOptions = [true, offerPaypalOption, offerAchOption].filter(Boolean).length;

	useEffect(() => {
		if (!payouts) {
			loadPayouts();
		}
	}, []);

	if (!accountConnection) return null;

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				{payouts && payouts.length > 0 && (
					<>
						<h2>{t('Scheduled Payouts')}</h2>
						{payouts.map(payout => {
							let meta;
							const hasAdvance = !!payout.advanceOfferKey;
							const advanceAvailable = payout.advanceOffers.length > 0;
							const isScheduled = payout.status === 'ready';

							if (hasAdvance) {
								meta = (
									<>
										<span style={{ textDecoration: 'line-through' }}>
											{moment(payout.achPostingDate || payout.date).format('M/D/YYYY')}
										</span>
										<img src={frontMeMetaIcon} />
										{moment(payout.advanceDate).format('M/D/YYYY')}
									</>
								);
							} else if (advanceAvailable) {
								meta = (
									<>
										{moment(payout.achPostingDate || payout.date).format('M/D/YYYY')}
										<img src={frontMeOfferMetaIcon} />
										<span style={{ color: '#D6625E' }}>{t('Front Me® Available')}</span>
									</>
								);
							} else if (isScheduled) {
								meta = (
									<>
										{moment(payout.date).format('LLL')} - {t('Scheduled')}
									</>
								);
							} else {
								meta = <>{moment(payout.achPostingDate || payout.date).format('M/D/YYYY')}</>;
							}

							return (
								<Link
									to={`../payouts/${payout.id}`}
									key={payout.id}
									className="box"
									style={{
										padding: 20,
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										cursor: 'pointer',
										color: '#1b1b21',
									}}>
									<div>
										<div style={{ fontWeight: '600' }}>{payout.description}</div>
										<div style={{ fontSize: 12, color: '#657C9D' }}>{meta}</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<span style={{ color: '#8DA4C4', fontSize: 18, marginRight: 12 }}>
											{fromCents(payout.amount)}
										</span>
										<img src={caretRight} />
									</div>
								</Link>
							);
						})}
						<div style={{ height: 60 }} />
					</>
				)}
				<h2>
					{accountConnection.status === 'active'
						? upgrading
							? t('Upgrade your connection.')
							: t('Edit your connection.')
						: t('OptionsHeadline', { useCase, offerAchOption, offerPaypalOption, enterpriseName })}
				</h2>
				{accountConnection.type !== 'direct' && !hasFailedNerveKyc && (
					<div className="box option-box with-app">
						<h3 style={{ marginRight: 'calc(20% - 40px)' }}>
							{t('ManifestOptionHeadline', { appName, enterpriseName })}
						</h3>
						<ul className="small" style={{ marginRight: 'calc(68% - 130px)' }}>
							<li>{t('Business debit and savings for creators.')}</li>
							<li>{t('Collect money with invoices.')}</li>
							<li>{t('Automate tax savings.')}</li>
							<li>{t('Apps for iOS + Android.')}</li>
						</ul>
						<Button centered={false} small style={buttonStyle} to="../sign-up">
							{t('Open in 1 minute.')}
						</Button>
						{numberOfOptions > 1 && (
							<span className="small">
								{t('Already have Manifest?', { appName })} <Link to="../sign-in">{t('Sign in.')}</Link>
							</span>
						)}
					</div>
				)}
				{accountConnection.type === 'direct' && (
					<div className="box option-box">
						<h3 style={{ marginRight: 'calc(20% - 40px)' }}>
							{t('Sign into Manifest to modify your connection.')}
						</h3>
						<span className="data">{accountConnection.destination}</span>
						<Button centered={false} style={buttonStyle} to="../sign-in">
							{t('Sign In')}
						</Button>
					</div>
				)}
				{numberOfOptions === 1 && accountConnection.status !== 'active' && (
					<div className="box option-box">
						<h3>{t('Already have Manifest?', { appName })}</h3>
						<Button centered={false} theme="secondary" small style={buttonStyle} to="../sign-in">
							{t('Sign In')}
						</Button>
					</div>
				)}
				{!upgrading && offerAchOption && (
					<div className="box option-box">
						<h3>{t('AchOptionHeadline')}</h3>
						{accountConnection.type === 'ach' && (
							<span className="data">{accountConnection.destination}</span>
						)}
						<Button centered={false} theme="secondary" small style={buttonStyle} to="../bank-info">
							{t('Enter Routing/Account Info')}
						</Button>
					</div>
				)}
				{!upgrading && offerPaypalOption && (
					<div className="box option-box">
						<h3>{t('PaypalOptionHeadline')}</h3>
						{accountConnection.type === 'paypal' && (
							<span className="data">{accountConnection.destination}</span>
						)}
						<Button centered={false} theme="secondary" small style={buttonStyle} to="../paypal-venmo-info">
							{t('Link PayPal/Venmo')}
						</Button>
					</div>
				)}
				{accountConnection.type !== 'direct' && hasFailedNerveKyc && (
					<div className="box option-box">
						<h3 style={{ marginRight: 'calc(20% - 40px)' }}>{t('Have a Manifest account?')}</h3>
						<Button small theme="subtle" centered={false} style={buttonStyle} to="../sign-in">
							{t('Sign In')}
						</Button>
					</div>
				)}
				{!upgrading && accountConnection.status === 'active' && !hasOnlyPayoutPermission && (
					<div className="box option-box">
						<h3>{t('DisconnectHeadline', { enterpriseName })}</h3>
						<Button centered={false} theme="subtle" small style={buttonStyle} to="../disconnect">
							{t('Disconnect')}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}
