import { useEffect, useState, forwardRef } from 'react';
import { Button, BackButton } from '../components';
import { TextField, FormControl, FormHelperText, FormControlLabel, Checkbox, RadioGroup, Radio } from '@mui/material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useConnectStore, useLanguageStore, useDocumentStore } from '../stores';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import parsePhoneNumber from 'libphonenumber-js';

export const MuiPhoneInput = forwardRef(({ id, ...props }, ref) => (
	<PhoneInput
		{...props}
		containerClass="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary Mui-focused MuiInputBase-formControl"
		inputClass="MuiFilledInput-input MuiInputBase-input"
		inputProps={{ id, ref }}
		country="us"
		placeholder=""
	/>
));

export default function BankInfo() {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		register,
		unregister,
		getValues,
	} = useForm();
	const navigate = useNavigate();
	const { t } = useLanguageStore();
	const { connectPaypal } = useConnectStore();
	const type = useWatch({ control, name: 'type', defaultValue: 'email' });
	const [submitting, setSubmitting] = useState(false);
	const { documentClick } = useDocumentStore();

	const onSubmit = async ({ type, mobileNumber, email }) => {
		setSubmitting(true);

		try {
			if (type === 'mobileNumber') {
				await connectPaypal({ externalAddress: '+' + mobileNumber });
			} else if (type === 'email') {
				await connectPaypal({ externalAddress: email });
			}

			navigate('../complete');
		} catch (e) {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		if (type === 'email') {
			reset({
				...getValues(),
				type: 'email',
				email: '',
				mobileNumber: '+1',
			});
			unregister(['mobileNumber']);
			register('email', {
				required: t('IsRequired', { field: t('Email Address') }),
				pattern: {
					value: /\S+@\S+\.\S+/,
					message: t('EnterValid', { field: t('Email Address') }),
				},
			});
		} else {
			reset({
				...getValues(),
				type: 'mobileNumber',
				email: '',
				mobileNumber: '+1',
			});
			unregister(['email']);
			register('mobileNumber', {
				required: t('IsRequired', { field: t('Mobile Number') }),
				validate: {
					correct: v => {
						try {
							const parsedMobileNumber = parsePhoneNumber('+' + v);
							return parsedMobileNumber.isPossible()
								? true
								: t(`Your mobile number looks invalid. Please review it.`);
						} catch (e) {
							return t(`Your mobile number looks invalid. Please review it.`);
						}
					},
				},
			});
		}
	}, [type]);

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('Enter your Paypal or Venmo info.')}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="type"
						render={({ field }) => (
							<div
								className="box"
								style={{ height: 56, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<FormControl {...field} component="fieldset">
									<RadioGroup aria-label="type" name="radio-buttons-group" row defaultValue="email">
										<FormControlLabel
											value="email"
											control={<Radio />}
											label={t('Email Address')}
										/>
										<FormControlLabel
											value="mobileNumber"
											control={<Radio />}
											label={t('Mobile Number')}
										/>
									</RadioGroup>
								</FormControl>
							</div>
						)}
						control={control}
					/>
					{type === 'email' && (
						<Controller
							name="email"
							render={({ field }) => (
								<TextField
									{...field}
									id="email"
									label={t('Email Address')}
									variant="filled"
									sx={{ my: 1, width: '100%' }}
									helperText={errors?.email?.message}
									error={!!errors?.email}
								/>
							)}
							control={control}
							defaultValue=""
						/>
					)}
					{/* {type === 'mobileNumber' && (
						<Controller
							name="mobileNumber"
							render={({ field }) => (
								<FormControl
									sx={{ my: 1, width: '100%' }}
									variant="filled"
									error={!!errors?.mobileNumber}>
									<InputLabel htmlFor="mobileNumber">{t('Mobile Number')}</InputLabel>
									<FilledInput {...field} id="mobileNumber" inputComponent={TextMaskMobileNumber} />
									{!!errors?.mobileNumber && (
										<FormHelperText error={!!errors?.mobileNumber}>
											{errors?.mobileNumber?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: t('Mobile Number') }),
								pattern: {
									value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i,
									message: t(`Please enter your 10-digit number.`),
								},
							}}
						/>
					)} */}

					{/* {type === 'mobileNumber' && (
						<Controller
							name="mobileNumber"
							render={({ field: { onChange, ...rest } }) => {
								// console.log(rest);
								return (
									<FormControl
										sx={{ my: 1, width: '100%', height:  }}
										variant="filled"
										error={!!errors?.mobileNumber}>
										<InputLabel htmlFor="mobileNumber">{t('Mobile Number')}</InputLabel>
										<MuiPhoneNumber
											{...rest}
											onChange={value => {
												// console.log(value);
												setValue('mobileNumber', value, { shouldValidate: true });
											}}
											id="mobileNumber"
											defaultCountry={'us'}
										/>
										{!!errors?.mobileNumber && (
											<FormHelperText error={!!errors?.mobileNumber}>
												{errors?.mobileNumber?.message}
											</FormHelperText>
										)}
									</FormControl>
								);
							}}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: t('Mobile Number') }),
								validate: {
									correct: v => {
										console.log(v);
										return v.length !== 10 ? t(`Please enter your 10-digit number.`) : true;
									},
								},
							}}
						/>
					)} */}

					{type === 'mobileNumber' && (
						<Controller
							name="mobileNumber"
							render={({ field }) => (
								<FormControl
									sx={{ my: 1, width: '100%' }}
									variant="filled"
									error={!!errors?.mobileNumber}>
									<InputLabel htmlFor="mobileNumber" style={{ marginLeft: 36 }}>
										{t('Mobile Number')}
									</InputLabel>
									<FilledInput {...field} id="mobileNumber" inputComponent={MuiPhoneInput} />
									{!!errors?.mobileNumber && (
										<FormHelperText error={!!errors?.mobileNumber}>
											{errors?.mobileNumber?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
							control={control}
							defaultValue=""
						/>
					)}

					<Controller
						name="termsAgreement"
						render={({ field }) => (
							<FormControl
								sx={{ my: 1, width: '100%' }}
								variant="filled"
								error={!!errors?.termsAgreement}>
								<div
									className="box"
									style={{
										height: 56,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										margin: 0,
									}}>
									<FormControlLabel
										control={<Checkbox {...field} id="termsAgreement" />}
										label={
											<>
												{t('TermsOfServicePre')}
												<a
													href="#"
													onClick={documentClick('connect-terms')}
													target="_blank"
													rel="noreferrer">
													{t('ConnectTermsLink')}
												</a>
												.
											</>
										}
									/>
								</div>
								{!!errors?.termsAgreement && (
									<FormHelperText error={!!errors?.termsAgreement}>
										{errors?.termsAgreement?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: 'You must agree to continue.',
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
						{t('Submit')}
					</Button>
				</form>
			</div>
		</div>
	);
}
