import { useState } from 'react';
import { Button, BackButton } from '../components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';
import { useToggle } from '../util/hooks';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuthStore, useLanguageStore, useAlertStore, useConnectStore } from '../stores';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useQueryString from 'use-query-string';

//helpful
//https://medium.com/@andresss/using-material-ui-with-react-hook-form-is-this-simple-d8383941fafe

export default function Login() {
	const { login } = useAuthStore();
	const { t } = useLanguageStore();
	const { alert } = useAlertStore();
	const { appName } = useConnectStore();
	const [submitting, setSubmitting] = useState(false);
	const [showPassword, toggleShowPassword] = useToggle(false);

	const [{ email }] = useQueryString(window.location);

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const onSubmit = async form => {
		const email = form.email.trim();
		const password = form.password;
		setSubmitting(true);

		try {
			await login(email, password);
			navigate('../verify-mobile');
		} catch (e) {
			setSubmitting(false);
			alert(t('Oops!'), t(e.message));
		}
	};

	const gotoForgotPassword = () => {
		const email = getValues().email?.trim();
		const isValid = email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
		if (isValid) {
			navigate(`../forgot-password?email=${encodeURIComponent(email)}`);
		} else {
			navigate(`../forgot-password`);
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('Sign in to Manifest.', { appName })}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="email"
						render={({ field }) => (
							<TextField
								{...field}
								autoFocus={!email}
								id="email"
								label={t('Email Address')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.email?.message}
								error={!!errors?.email}
							/>
						)}
						control={control}
						defaultValue={email}
						rules={{
							required: t('IsRequired', { field: t('Email Address') }),
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: t('EnterValid', { field: t('Email Address') }),
							},
						}}
					/>

					<Controller
						name="password"
						render={({ field }) => (
							<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.password}>
								<InputLabel htmlFor="password">{t('Password')}</InputLabel>
								<FilledInput
									{...field}
									id="password"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={toggleShowPassword}
												onMouseDown={event => event.preventDefault()}
												edge="end">
												{showPassword ? (
													<Visibility color="secondary" />
												) : (
													<VisibilityOff color="secondary" />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								{!!errors?.password && (
									<FormHelperText error={!!errors?.password}>
										{errors?.password?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Password') }),
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60, marginBottom: 10 }}>
						{t('Sign In')}
					</Button>
				</form>
				<Button onClick={gotoForgotPassword} enabled={!submitting} small theme="subtle" style={{ width: 240 }}>
					{t('Forgot your password?')}
				</Button>
			</div>
		</div>
	);
}
