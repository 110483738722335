import { useState } from 'react';
import { Button, BackButton } from '../components';
import { useAlertStore, useLanguageStore } from '../stores';
import API from '../util/API';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import useQueryString from 'use-query-string';

export default function ForgotPassword() {
	const { alert } = useAlertStore();
	const { t } = useLanguageStore();
	const [submitting, setSubmitting] = useState(false);
	const navigate = useNavigate();

	const [{ email }] = useQueryString(window.location);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = async form => {
		let email = form.email.trim();
		setSubmitting(true);

		try {
			await API.post(`/auth/forgot-password`, { email });
			navigate(`../reset-password?email=${encodeURIComponent(email)}`);
			setSubmitting(false);
		} catch (e) {
			setSubmitting(false);
			alert(t('Oops!'), t(e.message));
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t(`We'll email you a reset code.`)}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="email"
						render={({ field }) => (
							<TextField
								{...field}
								id="email"
								label={t('Email Address')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.email?.message}
								error={!!errors?.email}
							/>
						)}
						control={control}
						defaultValue={email}
						rules={{
							required: t('IsRequired', { field: t('Email Address') }),
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: t('EnterValid', { field: t('Email Address') }),
							},
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60, marginBottom: 10 }}>
						{t('Submit')}
					</Button>
				</form>
			</div>
		</div>
	);
}
