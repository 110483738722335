import { useMemo, useState, useEffect } from 'react';
import { Button, BackButton } from '../components';
import { useParams, Link } from 'react-router-dom';
import { useLanguageStore, useConnectStore, useDocumentStore, useAlertStore } from '../stores';
import moment from 'moment';
import { fromCents } from '../util/string';
import frontMeIcon from '../assets/frontMeIcon.svg';
import { FormControl, FormHelperText, FormControlLabel, RadioGroup, Radio, Checkbox } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export default function PayoutDetails() {
	const { t } = useLanguageStore();
	const { payoutId } = useParams();
	const { payouts, loadPayouts, acceptAdvanceOffer } = useConnectStore();
	const { documentClick } = useDocumentStore();
	const { alert } = useAlertStore();
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const [submitting, setSubmitting] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!payouts) {
			loadPayouts();
		}
	}, []);

	const payout = useMemo(() => payouts && payouts.find(p => p.id === payoutId), [payoutId, payouts]);
	useEffect(() => {
		if (payout && payout.advanceOffers?.length) {
			reset({
				key: payout.advanceOffers[0].key,
			});
		}
	}, [payout]);

	if (!payouts) return null;

	if (payouts && !payout)
		return (
			<div className="page-container centered">
				<div className="page">
					<h1>{t('Oops!')}</h1>
					<p>{t('Error 404 — Page not found.')}</p>
				</div>
			</div>
		);

	const submit = async ({ key }) => {
		setSubmitting(true);
		try {
			await acceptAdvanceOffer({ payoutId, offerKey: key });
			navigate('../options');
		} catch (e) {
			setSubmitting(false);
		}
	};

	const confirmSubmit = ({ key }) => {
		const offer = payout.advanceOffers.find(o => o.key === key);
		if (!offer) return;

		alert(t(`Confirm your Front Me.`), offer.confirmationPrompt, [
			{
				text: t('Confirm'),
				onClick: () => submit({ key }),
			},
			{ text: t('Cancel') },
		]);
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton to="../options" />
				<h2>{t('Payout Details')}</h2>

				<div
					className="box"
					style={{
						padding: 20,
						marginBottom: 60,
					}}>
					<div className="payout-details-row">
						<div className="label">{t(`Sends On`)}</div>
						<div className="value">{moment(payout.achPostingDate || payout.date).format('M/D/YYYY')}</div>
					</div>
					<div className="payout-details-row">
						<div className="label">{t(`Amount`)}</div>
						<div className="value">{fromCents(payout.amount)}</div>
					</div>
					<div className="payout-details-row">
						<div className="label">{t(`Description`)}</div>
						<div className="value">{payout.description}</div>
					</div>
					{!!payout.destination && (
						<div className="payout-details-row">
							<div className="label">{t(`Destination`)}</div>
							<div className="value">{payout.destination}</div>
						</div>
					)}
				</div>

				{payout.advanceOfferKey && (
					<>
						<h2 style={{ marginBottom: 0 }}>{t('Front Me® Details')}</h2>
						<span style={{ marginBottom: 16, fontSize: 12 }}>
							{t('Offer accepted on')} {moment(payout.advanceAcceptedAt).format('M/D/YYYY')} -{' '}
							<a
								href="#"
								style={{ textDecoration: 'underline' }}
								onClick={documentClick('front-me-terms')}
								target="_blank"
								rel="noreferrer">
								{t('Terms')}
							</a>
						</span>

						<div
							className="box"
							style={{
								padding: 20,
								marginBottom: 60,
							}}>
							<div className="payout-details-row">
								<div className="label">{t(`Scheduled For`)}</div>
								<div className="value">{moment(payout.advanceDate).format('M/D/YYYY')}</div>
							</div>
							<div className="payout-details-row">
								<div className="label">{t(`Payout Amount`)}</div>
								<div className="value">{fromCents(payout.amount)}</div>
							</div>
							<div className="payout-details-row">
								<div className="label">{t(`Fee`)}</div>
								<div className="value">{fromCents(payout.advanceFeeTotal)}</div>
							</div>
							<div className="payout-details-row">
								<div className="label">{t(`Receive`)}</div>
								<div className="value">{fromCents(payout.advanceAmount)}</div>
							</div>
						</div>
					</>
				)}

				{payout.advanceOffers.length > 0 && (
					<form onSubmit={handleSubmit(confirmSubmit)}>
						<div className="box advance-offers">
							<img src={frontMeIcon} alt="Front Me" />
							<h2>{t(`FrontMeHeadline`)}</h2>
							<Controller
								name="key"
								render={({ field }) => (
									<>
										<FormControl {...field} component="fieldset">
											<RadioGroup
												aria-label="key"
												name="radio-buttons-group"
												defaultValue={payout.advanceOffers[0].key}>
												{payout.advanceOffers.map((offer, index) => (
													<FormControlLabel
														className="offer"
														key={offer.key}
														value={offer.key}
														control={<Radio />}
														label={
															<>
																<div>
																	<div className="description">
																		{offer.description}
																	</div>
																	<div className="label">{offer.label}</div>
																</div>
																<div style={{ flex: 1 }}></div>
																<div style={{ textAlign: 'right' }}>
																	<div className="amount-label">{t('Receive')}</div>
																	<div className="label">
																		{fromCents(offer.advanceAmount)}
																	</div>
																</div>
															</>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>

										{!!errors?.key && (
											<FormControl>
												<FormHelperText error={!!errors?.key}>
													{errors?.key?.message}
												</FormHelperText>
											</FormControl>
										)}
									</>
								)}
								control={control}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									marginTop: 36,
								}}>
								<Controller
									name="termsAgreement"
									render={({ field }) => (
										<FormControl sx={{ my: 1 }} variant="filled" error={!!errors?.termsAgreement}>
											<FormControlLabel
												control={
													<Checkbox
														{...field}
														id="termsAgreement"
														style={{ transform: 'translateY(-10px)' }}
													/>
												}
												label={
													<>
														{t('CardholderBefore')}
														<a
															href="#"
															onClick={documentClick('front-me-terms')}
															target="_blank"
															rel="noreferrer">
															{t('FrontMeTermsLink')}
														</a>
														{t('CardholderAfter')}
													</>
												}
												style={{ alignItems: 'flex-start' }}
											/>

											{!!errors?.termsAgreement && (
												<FormHelperText error={!!errors?.termsAgreement}>
													{errors?.termsAgreement?.message}
												</FormHelperText>
											)}
										</FormControl>
									)}
									control={control}
									defaultValue=""
									rules={{
										required: t(`You must agree to the Front Me terms.`),
									}}
								/>
							</div>
							<Button loading={submitting} style={{ width: 236, marginTop: 4 }} type="submit">
								Front Me
							</Button>
						</div>
						<span style={{ padding: '10px 10%', fontSize: 12, textAlign: 'center' }}>
							{payout.advanceOffers.map(offer => offer.disclaimer).join(' ')}
						</span>
					</form>
				)}
			</div>
		</div>
	);
}
