import { useState } from 'react';
import { Button, BackButton, TextMaskCode } from '../components';
import API from '../util/API';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import { useToggle } from '../util/hooks';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuthStore, useLanguageStore, useAlertStore } from '../stores';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import useQueryString from 'use-query-string';

export default function ResetPassword() {
	const { register } = useAuthStore();
	const { t } = useLanguageStore();
	const { alert } = useAlertStore();
	const [submitting, setSubmitting] = useState(false);
	const [showPassword, toggleShowPassword] = useToggle(false);

	const [{ email }] = useQueryString(window.location);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const [score, setScore] = useState(0);
	const [scoreColor, setScoreColor] = useState('error');
	const [scoreProgress, setScoreProgress] = useState(0);
	const [scoreLabel, setScoreLabel] = useState(null);

	const onPasswordChange = event => {
		const password = event.target.value;
		let score = zxcvbn(password).score;
		if (score === 0 && password.length > 0) {
			score = 1;
		}
		setScore(score);

		if (password.length === 0) {
			setScoreLabel(null);
			setScoreColor('error');
			setScoreProgress(0);
		} else if (score === 0) {
			setScoreLabel(t('Too Weak'));
			setScoreColor('error');
			setScoreProgress(0);
		} else if (score === 1) {
			setScoreLabel(t('Too Weak'));
			setScoreColor('error');
			setScoreProgress(15);
		} else if (score === 2) {
			setScoreLabel(t('Good'));
			setScoreColor('warning');
			setScoreProgress(50);
		} else if (score === 3) {
			setScoreLabel(t('Strong'));
			setScoreColor('info');
			setScoreProgress(75);
		} else if (score === 4) {
			setScoreLabel(t('Show-off'));
			setScoreColor('success');
			setScoreProgress(100);
		}
	};

	const onSubmit = async form => {
		const password = form.password;
		const code = form.code;
		setSubmitting(true);

		try {
			await API.post(`/auth/reset-password`, { email, password, code });
			alert(t(`Your password has been reset!`));
			setSubmitting(false);
			navigate(`../sign-in?email=${encodeURIComponent(email)}`);
		} catch (e) {
			setSubmitting(false);
			alert(t('Oops!'), t(e.message));
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('Check your email.')}</h2>
				<p>{t('We emailed you a 6-digit code. Enter it below, along with your new password.')}</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="code"
						render={({ field }) => (
							<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.code}>
								<InputLabel htmlFor="code">{t('6-Digit Code')}</InputLabel>
								<FilledInput {...field} autoFocus id="code" inputComponent={TextMaskCode} />
								{!!errors?.code && (
									<FormHelperText error={!!errors?.code}>{errors?.code?.message}</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue={''}
						rules={{
							required: t('IsRequired', { field: t('6-Digit Code') }),
							validate: {
								correct: v =>
									v.length !== 6 ? t(`Please enter the 6-digit code we texted you.`) : true,
							},
						}}
					/>

					<Controller
						name="password"
						render={({ field }) => (
							<FormControl
								sx={{ my: 1, width: '100%' }}
								variant="filled"
								error={!!errors?.password}
								onChange={onPasswordChange}>
								<InputLabel htmlFor="password">{t('Password')}</InputLabel>
								<FilledInput
									{...field}
									id="password"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={toggleShowPassword}
												onMouseDown={event => event.preventDefault()}
												edge="end">
												{showPassword ? (
													<Visibility color="secondary" />
												) : (
													<VisibilityOff color="secondary" />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								{score > 0 && (
									<LinearProgress variant="determinate" value={scoreProgress} color={scoreColor} />
								)}
								{(!!errors?.password || !!scoreLabel) && (
									<FormHelperText error={!!errors?.password}>
										{errors?.password?.message || scoreLabel}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Password') }),
							validate: {
								correct: v =>
									zxcvbn(v).score < 2 ? `${t(`Password strength:`)} ${t(`Too Weak`)}` : true,
							},
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
						{t('Submit')}
					</Button>
				</form>
			</div>
		</div>
	);
}
