import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useEffect, useState } from 'react';
import { useLanguageStore, useAuthStore, useConnectStore, useDocumentStore } from '../stores';
import { Button, BackButton, PermissionsBox } from '../components';
import { useForm, Controller } from 'react-hook-form';
import {
	FormControlLabel,
	Checkbox,
	FormHelperText,
	InputLabel,
	Avatar,
	FormControl,
	MenuItem,
	Select,
} from '@mui/material';
import { useForceSession } from '../util/hooks';
import Loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function Connect() {
	useForceSession();

	const { t } = useLanguageStore();
	const { profile, refresh } = useAuthStore();
	const [submitting, setSubmitting] = useState(false);
	const { enterpriseName, connectBusinessProfileId } = useConnectStore();
	const { documentClick } = useDocumentStore();
	const navigate = useNavigate();

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (!profile) {
			refresh();
		} else {
			reset(
				{
					businessProfileId: profile.businessOwnership[0].businessProfile.id,
				},
				{
					errors: true,
					dirtyFields: true,
				},
			);
		}
	}, [profile, refresh, reset]);

	const onSubmit = async ({ businessProfileId }) => {
		setSubmitting(true);

		try {
			await connectBusinessProfileId(businessProfileId);
			navigate('../complete');
		} catch (e) {
			setSubmitting(false);
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t('ConnectHeadline', { enterpriseName })}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{!profile && (
						<Loader
							type="Oval"
							color="#dee3eb"
							height={60}
							width={60}
							style={{ alignSelf: 'center', marginTop: '100px' }}
						/>
					)}
					{profile && (
						<>
							<Controller
								name="businessProfileId"
								render={({ field }) => (
									<FormControl
										sx={{ mt: 5, mb: 1, width: '100%' }}
										variant="filled"
										error={!!errors?.password}>
										<InputLabel htmlFor="businessProfileId">{t('Profile')}</InputLabel>
										<Select {...field} id="businessProfileId" label="Profile">
											{profile.businessOwnership.map(({ businessProfile }) => (
												<MenuItem value={businessProfile.id} key={businessProfile.id}>
													<div className="profile-menu-item">
														<Avatar
															src={businessProfile.avatar}
															sx={{ width: 40, height: 40 }}
														/>
														<div className="names">
															<p className="nickname">{businessProfile.nickname}</p>
															<p className="display-name">
																{businessProfile.displayName}
															</p>
														</div>
													</div>
												</MenuItem>
											))}
										</Select>
										{!!errors?.businessProfileId && (
											<FormHelperText error={!!errors?.businessProfileId}>
												{errors?.businessProfileId?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'Please select a profile.',
								}}
							/>

							<PermissionsBox />

							<Controller
								name="termsAgreement"
								render={({ field }) => (
									<FormControl
										sx={{
											mb: 5,
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
										variant="filled"
										error={!!errors?.termsAgreement}>
										<FormControlLabel
											control={<Checkbox {...field} id="termsAgreement" />}
											label={
												<>
													{t('TermsOfServicePre')}
													<a
														href="#"
														onClick={documentClick('connect-terms')}
														target="_blank"
														rel="noreferrer">
														{t('ConnectTermsLink')}
													</a>
													.
												</>
											}
										/>

										{!!errors?.termsAgreement && (
											<FormHelperText error={!!errors?.termsAgreement}>
												{errors?.termsAgreement?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'You must agree to continue.',
								}}
							/>

							<Button type="submit" enabled={!submitting} theme="tertiary" style={{ width: 240 }}>
								{t('Connect')}
							</Button>
						</>
					)}
				</form>
			</div>
		</div>
	);
}
