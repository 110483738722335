import { useLanguageStore } from '../stores';

export default function NotFound() {
	const { t } = useLanguageStore();

	return (
		<div className="page-container centered">
			<div className="page">
				<h1>{t('Oops!')}</h1>
				<p>{t('Error 404 — Page not found.')}</p>
			</div>
		</div>
	);
}
