import { useAlertStore, useLanguageStore, useDocumentStore } from './stores';
import { Outlet } from 'react-router-dom';
import React from 'react';
import Modal from 'react-modal';
import { Button, BackButton } from './components';
import ReactMarkdown from 'react-markdown';
import Loader from 'react-loader-spinner';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default function App() {
	const { activeAlert } = useAlertStore();
	const { title, message, buttons } = activeAlert || {};
	const { document, loading, dismiss } = useDocumentStore();

	return (
		<div>
			<Outlet />
			<Modal portalClassName="alert-modal" isOpen={!!activeAlert} shouldCloseOnOverlayClick={false}>
				<h2 style={{ fontSize: 16, marginBottom: 8, marginTop: 0 }}>{title}</h2>
				<div style={{ fontSize: 14, marginBottom: 8, marginTop: 0 }}>{message}</div>
				{(!buttons || buttons.length === 0) && (
					<Button
						onClick={() => useAlertStore.getState().dismiss()}
						theme="tertiary"
						style={{ width: 240, marginTop: 10 }}>
						{useLanguageStore.getState().t('OK')}
					</Button>
				)}
				{buttons &&
					buttons.map((item, index) => (
						<Button
							key={index}
							onClick={() => {
								useAlertStore.getState().dismiss();
								item.onClick && item.onClick();
							}}
							theme={index === 0 ? 'tertiary' : 'subtle'}
							small={index > 0}
							style={{ width: 240, marginTop: 10 }}
							icon={item.icon}>
							{item.text}
						</Button>
					))}
			</Modal>
			<Modal
				portalClassName="document-modal"
				isOpen={!!(loading || document)}
				shouldCloseOnOverlayClick={true}
				onRequestClose={() => dismiss()}>
				<BackButton style={{ top: 0 }} close onClick={() => dismiss()} />
				{loading && (
					<Loader
						type="Oval"
						color="#dee3eb"
						height={100}
						width={100}
						style={{ alignSelf: 'center', marginTop: '200px', marginBottom: '20px' }}
					/>
				)}
				{!loading && (
					<div className="content">
						<ReactMarkdown linkTarget="_blank">{document}</ReactMarkdown>
					</div>
				)}
			</Modal>
		</div>
	);
}
