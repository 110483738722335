import { useLanguageStore, useConnectStore } from '../stores';
import { Button } from '../components';

export default function Complete() {
	const { t } = useLanguageStore();
	const { enterpriseName, returnToEnterprise, canReturnToEnterprise } = useConnectStore();

	return (
		<div className="page-container centered">
			<div className="page">
				<h1>{t('All done!')}</h1>

				{canReturnToEnterprise() && (
					<>
						<p>{t('ConnectionComplete', { enterpriseName })}</p>
						<Button
							small
							theme="tertiary"
							onClick={returnToEnterprise}
							style={{ width: 240, marginTop: 60 }}>
							{t('BackTo', { enterpriseName })}
						</Button>
					</>
				)}
				{!canReturnToEnterprise() && (
					<>
						<p>{t('You can close this window.')}</p>
					</>
				)}
			</div>
		</div>
	);
}
