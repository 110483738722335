import { useLanguageStore, useConnectStore } from '../stores';

export default function PermissionsBox() {
	const { t } = useLanguageStore();
	const { enterpriseName, permissions, appName } = useConnectStore();

	return (
		<div className="box permissions-box">
			<span className="headline">{t('PermissionsBoxHeadline', { enterpriseName })}</span>
			<ul>
				{permissions.map(key => {
					const [name, durationInDays] = key.split('+');
					return <li key={key}>{t('PermissionDescription', { name, durationInDays, appName })}</li>;
				})}
			</ul>
		</div>
	);
}
