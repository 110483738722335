import { useEffect, useState } from 'react';
import { useLanguageStore, useAuthStore, useConnectStore } from '../stores';
import { Button, BackButton } from '../components';
import { useForceSession, usePhyllo } from '../util/hooks';

export default function Waitlist() {
	useForceSession();
	const { t } = useLanguageStore();
	const { user } = useAuthStore();
	const { enterpriseName, offerAchOption, offerPaypalOption } = useConnectStore();
	const [headline, setHeadline] = useState('');
	const { loading, launch } = usePhyllo();
	const hasOtherOptions = offerAchOption || offerPaypalOption;

	useEffect(() => {
		if (user) {
			setHeadline(t('GotIt', { nickname: user.nickname }));
		}
	}, [user]);

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{headline}</h2>
				<p>
					{t(
						`Looks like you didn't qualify for an automatic invite. Improve your chances of getting in by connecting more profiles!`,
					)}
				</p>
				<p>{hasOtherOptions && t(`NoPhylloSecondChanceAch`, { enterpriseName })}</p>
				<Button loading={loading} onClick={launch} style={{ width: 280, marginTop: 40 }}>
					{t('Connect More Profiles')}
				</Button>
				{hasOtherOptions && (
					<Button small theme="subtle" to={'../options'} style={{ width: 280, marginTop: 10 }}>
						{t('Use Another Method')}
					</Button>
				)}
			</div>
		</div>
	);
}
