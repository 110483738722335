import './App.scss';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useLanguageStore, useConnectStore } from './stores';
import { Button } from './components';

export default function App() {
	const { language } = useParams();
	const { setLanguage, t } = useLanguageStore();
	const { returnToEnterprise, canReturnToEnterprise, error, enterpriseName } = useConnectStore();

	useEffect(() => {
		setLanguage(language);
	}, [language, setLanguage]);

	if (error) {
		return (
			<div className="page-container centered">
				<div className="page">
					<h1>{t('Oops!')}</h1>
					<p>{t('Your link has expired. Please start over.')}</p>
					{canReturnToEnterprise() && (
						<Button
							small
							theme="tertiary"
							onClick={returnToEnterprise}
							style={{ width: 240, marginTop: 60 }}>
							{t('BackTo', { enterpriseName })}
						</Button>
					)}
				</div>
			</div>
		);
	} else {
		return <Outlet />;
	}
}
