import { useLanguageStore } from '../stores';

export default function AlertBox({ listItems, headline, children }) {
    const { t } = useLanguageStore();
    return (
        <div className="box alert-box">
            <span className="headline">{t(headline)}</span>
            <ul>
                {listItems.map((item, index) => {
                    return <li key={index}>{t(item?.value)}</li>;
                })}
            </ul>
        </div>
    );
}