import React from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function Button({
	small = false,
	centered = true,
	theme = 'primary',
	style,
	type,
	loading = false,
	enabled = true,
	children,
	to,
	onClick,
}) {
	let disabled = !enabled;
	const spinner = (
		<CircularProgress
			color="inherit"
			style={small ? { width: 16, height: 16, marginTop: 7 } : { width: 24, height: 24, marginTop: 13 }}
		/>
	);
	const className = (centered ? 'centered ' : '') + (small ? 'small ' : '') + theme;
	const containerStype = centered ? { alignSelf: 'stretch', display: 'flex', flexDirection: 'column' } : {};

	if (to) {
		return (
			<Link className="button" to={to} style={containerStype}>
				<button className={className} disabled={disabled || loading} style={style} type={type}>
					{loading ? spinner : children}
				</button>
			</Link>
		);
	} else {
		return (
			<button className={className} disabled={disabled || loading} onClick={onClick} style={style} type={type}>
				{loading ? spinner : children}
			</button>
		);
	}
}
