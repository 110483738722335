import { useState, useEffect } from 'react';
import { useAuthStore, useLanguageStore, useConnectStore, usePhylloStore } from '../stores';
import { useNavigate } from 'react-router-dom';
import API from '../util/API';

export const useToggle = (initialValue, otherValue) => {
	const [value, setValue] = useState(initialValue);
	const toggle = newValue => {
		if (newValue !== undefined && newValue.nativeEvent === undefined) {
			setValue(newValue);
		} else if (otherValue !== undefined) {
			if (value === initialValue) {
				setValue(otherValue);
			} else {
				setValue(initialValue);
			}
		} else {
			setValue(!value);
		}
	};
	return [value, toggle];
};

export const useForceSession = () => {
	const navigate = useNavigate();
	const { token } = useAuthStore();
	const { language } = useLanguageStore();
	const { token: connectToken } = useConnectStore();
	useEffect(() => {
		if (!token) {
			navigate(`/${language}/intro/${connectToken}`, { replace: true });
		}
	}, [token, navigate]);
};

export const useForceBetaAccessIfPhylloRequired = () => {
	const navigate = useNavigate();
	const { token, profile } = useAuthStore();
	const { language } = useLanguageStore();
	const betaAccess = profile?.betaAccess;

	const { token: connectToken, requirePhyllo } = useConnectStore();
	useEffect(() => {
		if (requirePhyllo && !betaAccess) {
			navigate(`/${language}/intro/${connectToken}`, { replace: true });
		}
	}, [token, navigate]);
};

export const usePhyllo = () => {
	const { token: connectToken } = useConnectStore();
	const { phylloConnect, launchPhylloConnect, refreshAccounts, onClose } = usePhylloStore();
	const { dismissStep, STEP_PHYLLO, refresh } = useAuthStore();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const launch = async () => {
		setLoading(true);
		await launchPhylloConnect();
		setLoading(false);
	};

	useEffect(() => {
		let listener1, listener2, listener3, listener4;

		if (phylloConnect) {
			listener1 = phylloConnect.on('exit', async (reason, userId) => {
				onClose();
				try {
					const { profiles } = await API.get('/banking/phyllo/profiles');
					if (profiles.length > 0) {
						await dismissStep(STEP_PHYLLO);
						await refresh();
						if (useAuthStore.getState().profile?.betaAccess) {
							navigate(`../open-now`);
						} else {
							navigate(`../creator-verification-needed`);
						}
					}
				} catch (e) {
					navigate(`../intro/${connectToken}`, { replace: true });
				}
			});
			listener2 = phylloConnect.on('tokenExpired', userId => {
				onClose();
				console.log(`onTokenExpired: ${userId}`);
			});
			listener3 = phylloConnect.on('accountConnected', (accountId, workplatformId, userId) => {
				if (accountId) {
					refreshAccounts(accountId);
				}
			});
			listener4 = phylloConnect.on('accountDisconnected', (accountId, workplatformId, userId) => {
				if (accountId) {
					refreshAccounts(accountId);
				}
			});

			phylloConnect.open();
		}

		return () => {
			listener1?.remove();
			listener2?.remove();
			listener3?.remove();
			listener4?.remove();
		};
	}, [phylloConnect]);

	return { loading, launch };
};
