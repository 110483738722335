import { useLanguageStore } from '../stores';
import { Button, BackButton } from '../components';
import { useForceSession } from '../util/hooks';

export default function KycManualReview() {
	useForceSession();
	const { t } = useLanguageStore();

	const emailSupport = () => {
		window.location.href = 'mailto:support@manifestfinancial.com';
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton logout />
				<h2>{t(`Your account is under manual review.`)}</h2>
				<p>
					{t(
						`We're working on verifying your account. Be on the lookout for an email from us soon. If you have any questions, please email us at support@manifestfinancial.com.`,
					)}
				</p>
				<Button onClick={emailSupport} style={{ width: 240, marginTop: 60 }}>
					{t('Email Support')}
				</Button>
			</div>
		</div>
	);
}
