import { useDocumentStore } from '../stores';
import { isProd } from '../util/config';

export default function BackButton({}) {
	const { loadMarkdown } = useDocumentStore();
	const onClick = () => {
		loadMarkdown(
			`# Sandbox Environment
## Just Testing
No live accounts, cards, or money will be used. 2FA is not required in sandbox, and specific (prefilled) test data must be used to pass KYC.
## Sandbox Testing
For more information about how sandbox differs from production, see the [Sandbox Testing](https://docs.manifestfinancial.com/reference/testing-in-sandbox) page in our docs.`,
		);
	};

	if (isProd) return null;

	return (
		<div className={`sandbox-chip`} onClick={onClick}>
			<span>Sandbox</span>
		</div>
	);
}
