import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguageStore, useAlertStore, useInviteStore } from '../stores';
import { useNavigate } from 'react-router-dom';
import { Button, SecurityChip, SandboxChip } from '../components';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import useQueryString from 'use-query-string';

export default function Invite() {
	const { t, language } = useLanguageStore();
	const { loadSession, verifyInviteAndCreateLinkToken, error, verificationPrompt } = useInviteStore();
	const navigate = useNavigate();
	const [init, setInit] = useState(false);
	const [{ redirect }] = useQueryString(window.location);

	const { alert } = useAlertStore();
	const [submitting, setSubmitting] = useState(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const { inviteToken } = useParams();

	const load = async inviteToken => {
		await loadSession(inviteToken);
		setInit(true);
	};

	useEffect(() => {
		if (inviteToken) {
			load(inviteToken);
		}
	}, [inviteToken, useInviteStore]);

	const onSubmit = async ({ verificationAnswer }) => {
		setSubmitting(true);

		try {
			const { token } = await verifyInviteAndCreateLinkToken(inviteToken, verificationAnswer);
			if (redirect) {
				navigate(`/${language}/intro/${token}?redirect=${redirect}`, { replace: true });
			} else {
				navigate(`/${language}/intro/${token}`, { replace: true });
			}
		} catch (e) {
			alert(t('Oops!'), t(e.message));
			setSubmitting(false);
		}
	};

	if (error) {
		return (
			<div className="page-container centered">
				<div className="page">
					<h1>{t('Oops!')}</h1>
					<p>{t('Your invite has expired.')}</p>
				</div>
			</div>
		);
	} else if (init) {
		return (
			<div className="page-container centered">
				<div className="form">
					<div className={`right-nav-button`}>
						<SandboxChip />
						<SecurityChip />
						<Link to={`/en/invite/${inviteToken}`}>EN</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
						<Link to={`/es/invite/${inviteToken}`}>ES</Link>
					</div>
					<h2>{t('Security check.')}</h2>
					<p>{verificationPrompt}</p>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name="verificationAnswer"
							render={({ field }) => (
								<TextField
									{...field}
									autoFocus={true}
									id="verificationAnswer"
									label={t('Verification Answer')}
									variant="filled"
									sx={{ my: 1, width: '100%' }}
									helperText={errors?.verificationAnswer?.message}
									error={!!errors?.verificationAnswer}
									inputProps={{
										maxLength: 40,
									}}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: t('Verification Answer') }),
							}}
						/>

						<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
							{t('Unlock')}
						</Button>
					</form>
				</div>
			</div>
		);
	} else {
		return null;
	}
}
