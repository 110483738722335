import create from 'zustand';
import API from '../util/API';
import { persist } from 'zustand/middleware';
import useAlertStore from './alert';
import useLanguageStore from './language';
import useConnectStore from './connect';

const LOGOUT_MINUTES = 10;

export default create(
	persist(
		(set, get) => ({
			//user needs to complete mfa
			mfaNeeded: false,
			//session
			token: null,
			//used during mfa
			elevatedToken: null,
			mobileNumberVerified: null,
			user: null,
			profile: null,
			logoutTimerId: null,
			STEP_PHYLLO: 0,
			nickname: null,
			dismissStep: async flagIndex => {
				await API.post(`/banking/profile/dismiss-step`, { flagIndex });
				get().refresh();
			},
			hasDismissedStep: flagIndex => get().profile?.dismissedStepFlags & Math.pow(2, flagIndex),
			startLogoutTimer: () => {
				const { clearLogoutTimer } = get();
				clearLogoutTimer();
				const logoutTimerId = setTimeout(() => {
					const { logout } = get();
					logout();
				}, LOGOUT_MINUTES * 60 * 1000);
				set({ logoutTimerId });
			},
			clearLogoutTimer: () => {
				const { logoutTimerId } = get();
				if (logoutTimerId) {
					clearTimeout(logoutTimerId);
				}
			},
			login: async (email, password) => {
				try {
					set({ isInitialized: false, mfaNeeded: false });
					const { whiteLabelApp } = useConnectStore.getState();
					let { elevatedToken, mobileNumberVerified } = await API.post(`/auth/login`, {
						email,
						password,
						whiteLabelApp,
					});
					set({ mfaNeeded: true, elevatedToken, mobileNumberVerified });
				} catch (e) {
					throw e;
				}
			},
			register: async ({
				email,
				mobileNumber,
				password,
				referralCode,
				affiliateCode,
				userSource,
				userSourceId,
				nickname,
			}) => {
				set({ isInitialized: false });
				const { whiteLabelApp } = useConnectStore.getState();
				let { elevatedToken, mobileNumberVerified } = await API.post(`/auth/register-admin`, {
					email,
					mobileNumber,
					password,
					referralCode,
					affiliateCode,
					product: 'pro',
					userSource,
					userSourceId,
					nickname,
					whiteLabelApp,
				});
				set({ mfaNeeded: true, elevatedToken, mobileNumberVerified, nickname });
			},
			modifyMobile: async mobileNumber => {
				const { elevatedToken } = get();
				await API.post(`/auth/modify-mobile`, { mobileNumber }, elevatedToken);
				set({ mfaNeeded: true, mobileNumberVerified: false });
			},
			submitMfa: async code => {
				const { elevatedToken, startLogoutTimer, refresh } = get();
				let { bankingToken: token } = await API.post(`/auth/verify-mobile`, { code }, elevatedToken);
				startLogoutTimer();
				set({ token, elevatedToken: null, mfaNeeded: false, mobileNumberVerified: true });
				refresh();
			},
			refresh: async () => {
				const { token } = get();
				if (token) {
					const [user, profile] = await Promise.all([API.get(`/user/profile`), API.get(`/banking/profile/`)]);
					set({ user, profile });
				}
			},
			confirmLogout: () => {
				const { logout } = get();
				useAlertStore.getState().alert(useLanguageStore.getState().t('Sign out?'), null, [
					{
						text: useLanguageStore.getState().t('Sign Out'),
						onClick: () => logout(),
					},
					{ text: useLanguageStore.getState().t('Cancel') },
				]);
			},
			logout: async () => {
				get().clearLogoutTimer();
				set({
					token: null,
					elevatedToken: null,
					mfaNeeded: false,
					isInitialized: true,
					mobileNumberVerified: null,
					user: null,
					profile: null,
				});
			},
		}),
		{
			name: 'auth',
			partialize: ({ token, elevatedToken, nickname }) => ({
				token,
				elevatedToken,
				nickname,
			}),
			getStorage: () => sessionStorage,
		},
	),
);
