import { useLanguageStore } from '../stores';

export const numberFormat = function (number, decimals) {
	if (number === null || number === undefined) return '---';

	let dec_point = '.';
	let thousands_sep = ',';
	if (useLanguageStore.getState().language === 'es') {
		dec_point = ',';
		thousands_sep = '.';
	}

	var parts = number.toFixed(decimals).split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

	return parts.join(dec_point);
};

export const fromCents = num => {
	if (num === null || num === undefined) return '---';
	if (num > 0) {
		return `$${numberFormat(num / 100, 2)}`;
	} else if (num < 0) {
		return `-$${numberFormat(Math.abs(num) / 100, 2)}`;
	} else {
		return useLanguageStore.getState().language === 'es' ? '$0,00' : '$0.00';
	}
};

export const fakeSSN = () => {
	let ssn = '1'; //must start with 1 to pass TP testing requirements
	for (let i = 0; i < 2; i++) {
		ssn += Math.floor(Math.random() * 10);
	}
	ssn += '-';

	for (let i = 0; i < 2; i++) {
		ssn += Math.floor(Math.random() * 10);
	}
	ssn += '-';

	for (let i = 0; i < 4; i++) {
		ssn += Math.floor(Math.random() * 10);
	}
	return ssn;
};

export const fakeDOB = () => {
	// need MM-DD-YYYY format for a person at least 18 years old
	const year = 2004 - Math.floor(Math.random() * 40);
	const month = Math.floor(Math.random() * 12) + 1;
	const day = Math.floor(Math.random() * 28) + 1;
	// needs to be padded
	const paddedMonth = month < 10 ? '0' + month : month;
	const paddedDay = day < 10 ? '0' + day : day;
	return `${paddedMonth}-${paddedDay}-${year}`;
};
