import { useState, useEffect } from 'react';
import { useLanguageStore, useConnectStore, useAuthStore } from '../stores';
import { Button } from '../components';
import QRCode from 'qrcode';

export default function Download() {
	const { t } = useLanguageStore();
	const { enterpriseName, returnToEnterprise, canReturnToEnterprise, appLink, appName } = useConnectStore();
	const { nickname } = useAuthStore();
	const [qrCode, setQrCode] = useState();

	// Generate QR code
	useEffect(() => {
		if (appLink) {
			QRCode.toDataURL(appLink, { width: 240, margin: 0 }).then(url => {
				setQrCode(url);
			});
		}
	}, [appLink]);

	return (
		<div className="page-container centered">
			<div className="page">
				{appLink && qrCode && (
					<>
						<h2 style={{ marginBottom: 40 }} className="display-linebreak">
							{t('AppLinkHeading', { appName, nickname })}
						</h2>
						<img src={qrCode} alt="QR code" />
						{canReturnToEnterprise() && (
							<Button onClick={returnToEnterprise} style={{ width: 300, marginTop: 60 }}>
								{t('BackTo', { enterpriseName })}
							</Button>
						)}
						{!canReturnToEnterprise() && <p style={{ marginTop: 40 }}>{t('You can close this window.')}</p>}
					</>
				)}
			</div>
		</div>
	);
}
