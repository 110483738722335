import create from 'zustand';
import useAlertStore from './alert';
import useLanguageStore from './language';
import useAuthStore from './auth';
import API from '../util/API';
import { isProd } from '../util/config';

const PhylloConnect = window.PhylloConnect;

export default create((set, get) => ({
	phylloConnect: null,
	loading: false,
	exiting: false,
	refreshAccounts: async accountId => {
		try {
			await API.post(`/banking/phyllo/profiles/refresh`, {
				accountId,
			});
		} catch (e) {
			console.log(e);
		}
	},
	launchPhylloConnect: async () => {
		set({ loading: true });

		try {
			const { userId, token } = await API.get(`/banking/phyllo/connect-token`);
			const environment = !isProd ? 'sandbox' : 'production';
			const clientDisplayName = 'Manifest';

			const config = { clientDisplayName, userId, token, environment };
			const phylloConnect = PhylloConnect.initialize(config);

			set({ loading: false, phylloConnect });
		} catch (e) {
			set({ loading: false, phylloConnect: null });

			useAlertStore
				.getState()
				.alert(useLanguageStore.getState().t('Oops!'), useLanguageStore.getState().t(e.message));
		}
	},
	onClose: () => {
		set({ phylloConnect: null });
	},
}));
