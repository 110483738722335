import create from 'zustand';
import alloy from '@alloyidentity/web-sdk';
import { sleep } from '../util/sleep';

const config = { key: process.env.REACT_APP_IOVATION_KEY, production: process.env.REACT_APP_BUILD_ENV === 'production' };

export default create((set) => ({
	iovationConnected: false,
	iovationBlocked: false,
    iovationBlackboxId: null,
	resetParams: () => {
		set({ iovationConnected: false, iovationBlocked: false });
	},
	initIovation: async () => {
		try {
			const winner = await Promise.race([alloy.init(config), sleep(3000)]);
			const successful = winner !== 'sleep';

			set({ iovationBlocked: !successful, iovationConnected: successful, iovationBlackboxId: winner?.iovationBlackboxId ?? null });
		} catch (e) {
			console.error('An error occurred initializing Alloy', e);
			set({ iovationConnected: false });
		}
	},
}));
