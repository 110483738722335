import { useState, useEffect } from 'react';
import { Button, BackButton, TextMaskCode } from '../components';
import { useAlertStore, useAuthStore, useLanguageStore, useConnectStore } from '../stores';
import { isProd } from '../util/config';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';

export default function MFA() {
	const { submitMfa, profile, STEP_PHYLLO, hasDismissedStep } = useAuthStore();
	const { alert } = useAlertStore();
	const { t } = useLanguageStore();
	const [submitting, setSubmitting] = useState(false);
	const navigate = useNavigate();
	const { requirePhyllo, appName } = useConnectStore();
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = async ({ code }) => {
		setSubmitting(true);

		try {
			await submitMfa(code);
		} catch (e) {
			setSubmitting(false);
			alert(t('Oops!'), t(e.message));
		}
	};

	useEffect(() => {
		if (profile) {
			const { businessOwnership, kycStatus, betaAccess } = profile;

			if (kycStatus === 'PENDING_STEP_UP') {
				navigate('../identity-verification-step-up');
			} else if (kycStatus === 'EXCEPTION' || kycStatus === 'INVALID_DATA' || kycStatus === 'PENDING') {
				navigate('../identity-verification-exception');
			} else if (kycStatus === 'MANUAL_REVIEW') {
				navigate('../identity-verification-manual-review');
			} else if (kycStatus === 'DENIED') {
				navigate('../identity-verification-denied');
			} else if (businessOwnership?.length > 0) {
				navigate('../connect');
			} else if (requirePhyllo && !betaAccess && !hasDismissedStep(STEP_PHYLLO)) {
				navigate('../link-profiles');
			} else if (requirePhyllo && !betaAccess) {
				navigate('../creator-verification-needed');
			} else {
				navigate('../open-now');
			}
		}
	}, [profile, navigate]);

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('We texted you a 6-digit code.')}</h2>
				<p>{t('Manifest uses security codes to protect accounts.', { appName })}</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="code"
						render={({ field }) => (
							<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.code}>
								<InputLabel htmlFor="code">{t('6-Digit Code')}</InputLabel>
								<FilledInput {...field} autoFocus id="code" inputComponent={TextMaskCode} />
								{!!errors?.code && (
									<FormHelperText error={!!errors?.code}>{errors?.code?.message}</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue={isProd ? '' : '000000'}
						rules={{
							required: t('IsRequired', { field: t('6-Digit Code') }),
							validate: {
								correct: v =>
									v.length !== 6 ? t(`Please enter the 6-digit code we texted you.`) : true,
							},
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60, marginBottom: 10 }}>
						{t('Verify')}
					</Button>
				</form>
			</div>
		</div>
	);
}
