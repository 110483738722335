import create from 'zustand';
import moment from 'moment';

const en = {
	dictionaryName: 'English',
	contentfulLocale: 'en-US',
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: function (number, withoutSuffix) {
			return withoutSuffix ? 'now' : 'a few seconds';
		},
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1mo',
		MM: '%dmo',
		y: '1y',
		yy: '%dy',
	},
	longDateFormat: {
		LLL: 'M/DD/YYYY',
	},
	PlatformFollowers: ({ platform }) => `${platform} Followers`,
	IsRequired: ({ field }) => `${field} is required.`,
	EnterValid: ({ field }) => `Please enter a valid ${field}`,
	PleaseEnterYour: ({ field }) => `Please enter your ${field}.`,
	ConfirmTransfer: ({ amount }) => `Transfer ${amount}`,
	ConfirmSend: ({ amount }) => `Send ${amount}`,
	ConfirmRequest: ({ amount }) => `Request ${amount}`,
	SendTo: ({ name }) => `Send To ${name}`,
	RequestFrom: ({ name }) => `Request From ${name}`,
	AddConnectionNoMoney: 'Friends can scan this code to connect to you. Or invite your contacts below.',
	AddConnectionBeforeMoney: 'Friends can scan this code to connect to you. Or invite contacts below and get paid',
	AddConnectionAfterMoney: 'when they open their account.',
	SignOutWarning: ({ protectionName }) =>
		`If this is your personal device, stay logged in to continue receiving notifications — your account is protected with your ${protectionName}.`,
	ProfilesDebit: ({ name }) => `${name} Debit`,
	TheyRequestedBeforeAmount: `They requested `,
	TheyRequestedAfterAmount: ` for`,
	YouRequestedBeforeAmount: `You requested `,
	YouRequestedAfterAmount: ` for`,
	IntroMoney: 'Money',
	IntroMoneyDetails: 'Separate music money from your personal\nmoney with free business checking.',
	IntroMusic: 'Music',
	IntroMusicDetails: 'Link your streaming + social platforms.\nKeep an eye on all parts of your business.',
	IntroMore: 'More',
	IntroMoreDetails: 'Instantly pay collaborators, automate\nsavings, and access 55,000+ free ATMs.',
	'No credit check. No monthly fees.': 'No credit check. No monthly fees.\n',
	TermsOfServicePre: `I agree to the `,
	TermsOfServiceLink: 'terms of service',
	TermsOfServicePost: '.',
	UserAddendumLink: `app terms`,
	AgreementBetweenFinal: ', and the ',
	AchTermsOfServiceLink: 'ACH terms',
	ConnectTermsLink: 'Connect terms',
	BusinessIntroPre: `First, we'll open your individual business account. This means that you `,
	BusinessIntroBold: 'only',
	BusinessIntroPost: ' need your personal information to open your free Manifest business checking account.',
	'Scan your ID to prefill your personal info.': 'Scan your ID to prefill\nyour personal info.',
	DateEntryFormat: 'mm-dd-yyyy',
	DateScanFormat: 'MM-DD-YYYY',
	PleaseFollowFormat: ({ format }) => `Please follow the ${format} format.`,
	EnterYourFull: ({ field }) => `Please enter your full ${field}`,
	CardholderBefore: `I agree to the `,
	CollabTermsLink: `collab terms`,
	CheckingTermsLink: 'checking terms',
	AgreementBetween: ', the ',
	CardholderLink: 'cardholder agreement',
	AchTermsLink: 'ACH service terms',
	FrontMeTermsLink: 'Front Me terms',
	CardholderAfter: '.',
	ApplicationSuccess: ({ name }) => `Your application was approved! Nice work, ${name}!`,
	SavingsTermsBefore: `I agree to the `,
	SavingsTermsLink: 'savings terms',
	SavingsTermsAfter: '.',
	'Opening account— This will only take around 20 seconds.':
		'Opening account—\nThis will only take around 20 seconds.',
	AchAuth:
		'I authorize Piermont Bank to electronically debit/credit my accounts in the next 2-3 business days. These transactions comply with all applicable laws.',
	'Add savings to enable these features.': 'Add savings to enable\nthese features.',
	GotIt: ({ nickname }) => `Got it, ${nickname}.`,
	ReferNetworkNoMoney: 'Use this link to help promote Manifest. Thanks!',
	ReferNetworkBeforeMoney: "When you use this link to help promote Manifest, you'll earn ",
	ReferNetworkAfterMoney: ' for each new account that signs up!',
	HourAbbreviation: 'hr',
	HourAbbreviationPlural: 'hrs',
	MinuteAbbreviation: 'min',
	MinuteAbbreviationPlural: 'mins',
	OnDate: ({ date }) => `on ${date}`,

	//new
	IntroHeadline: ({ enterpriseName, useCase, appName }) => {
		return (
			{
				generic: `${enterpriseName} is partnered with ${appName}.`,
				payout: `${enterpriseName} uses ${appName} to pay you.`,
				advance: `${enterpriseName} uses ${appName} to advance you money.`,
				loan: `${enterpriseName} uses ${appName} to loan you money.`,
				whitelabel: `${appName} is powered by Manifest.`,
			}[useCase] || `${enterpriseName} is partnered with ${appName}.`
		);
	},
	IntroDescription: `Manifest offers free business banking for creators.`,
	ConnectHeadline: ({ enterpriseName }) => `Connect to ${enterpriseName}.`,
	PermissionsBoxHeadline: ({ enterpriseName }) => `${enterpriseName} will be able to:`,
	OptionsHeadline: ({ useCase, offerAchOption, offerPaypalOption, enterpriseName }) => {
		const numberOfOptions = [true, offerPaypalOption, offerAchOption].filter(Boolean).length;
		if (numberOfOptions > 1) {
			return (
				{
					generic: `There are ${numberOfOptions} ways to connect.`,
					payout: `There are ${numberOfOptions} ways to get paid.`,
					advance: `There are ${numberOfOptions} ways to get your advance.`,
					loan: `There are ${numberOfOptions} ways to get your money.`,
				}[useCase] || `There are ${numberOfOptions} ways to connect.`
			);
		} else {
			return (
				{
					generic: `Connect to ${enterpriseName}.`,
					payout: `Connect to get paid.`,
					advance: `Connect to get your advance.`,
					loan: `Connect to get your money.`,
				}[useCase] || `Connect to ${enterpriseName}.`
			);
		}
	},
	ManifestOptionHeadline: ({ appName, enterpriseName }) =>
		`Open a free ${appName} account and get instant access to your money from ${enterpriseName}.`,
	AchOptionHeadline: `Use a different bank and get your money in 2-3 business days.`,
	PaypalOptionHeadline: `Use PayPal or Venmo and get your money in 7 days.`,
	SecondChanceAch: ({ enterpriseName }) =>
		`Don't worry, ${enterpriseName} can still pay you using a different method.`,
	NoPhylloSecondChanceAch: ({ enterpriseName }) =>
		`Alternatively, ${enterpriseName} can pay you using a different method.`,
	PermissionDescription: ({ name, durationInDays = 0, appName }) => {
		if (durationInDays > 0) {
			let durationLabel = `for the next ${durationInDays} days.`;
			if (durationInDays === 365) {
				durationLabel = `for 1 year.`;
			}
			return (
				{
					'payment.credit': `Pay your ${appName} account, and see the name(s) and email(s) associated with your account ${durationLabel}`,
					'payment.debit': `Debit your ${appName} account. (Don't worry, we'll always get your permission first.)`,
					personal_info: `See your personal info ${durationLabel}`,
					balance: `See your ${appName} account balances ${durationLabel}`,
					activity: `See your ${appName} account activity ${durationLabel}`,
					all_data: `See your ${appName} account balances, account activity and personal info ${durationLabel}`,
				}[name] || name
			);
		} else {
			return (
				{
					'payment.credit': `Pay your ${appName} account, and see the name(s) and email(s) associated with your account.`,
					'payment.debit': `Debit your ${appName} account. (Don't worry, we'll always get your permission first.)`,
					personal_info: `See your personal info.`,
					balance: `See your ${appName} account balances.`,
					activity: `See your ${appName} account activity.`,
					all_data: `See your ${appName} account balances, account activity and personal info.`,
				}[name] || name
			);
		}
	},
	BackTo: ({ enterpriseName }) => (enterpriseName ? `Back to ${enterpriseName}` : `Back`),
	ConnectionComplete: ({ enterpriseName }) => `You can now return to ${enterpriseName}.`,
	CurrentAchBanner: ({ enterpriseName }) => `Connected to ${enterpriseName} with`,
	CurrentManifestBanner: ({ enterpriseName }) => `Connected to ${enterpriseName} with`,
	NewPermissionsBanner: ({ enterpriseName }) => `${enterpriseName} is requesting new permissions.`,
	UpgradeToManifestBannerHeadline: ({ enterpriseName }) => `${enterpriseName} needs you to upgrade.`,
	UpgradeToManifestBannerBody: `Get a Manifest account to upgrade your connection.`,
	CheckingTypeAch: 'Checking',
	DisconnectHeadline: ({ enterpriseName }) => `Disconnect from ${enterpriseName}.`,
	DisconnectBodyAch: ({ enterpriseName }) =>
		`By disconnecting, you'll remove ${enterpriseName}'s ability to pay your bank account via ACH.`,
	DisconnectBodyManifest: ({ enterpriseName }) =>
		`By disconnecting, you'll remove ${enterpriseName}'s connection to your Manifest account. Your Manifest account will remain fully functional and available in the Manifest app.`,
	FrontMeHeadline: `Advance your payout with Front Me®`,
	DaysUntilSuffix: ({ daysUntil }) => ` - in ${daysUntil} ${daysUntil === 1 ? 'day' : 'days'}`,
	AppLinkHeading: ({ appName, nickname }) =>
		`Nice work${nickname ? `, ${nickname}` : ''}! Scan this code to\ndownload the ${appName} app and sign in.`,
	'Create a Manifest login.': ({ appName }) => `Create a ${appName} login.`,
	'Already have Manifest?': ({ appName }) => `Already have ${appName}?`,
	'Sign in to Manifest.': ({ appName }) => `Sign in to ${appName}.`,
	'Manifest uses security codes to protect accounts.': ({ appName }) =>
		`${appName} uses security codes to protect accounts.`,
};

const es = {
	dictionaryName: 'Español',
	contentfulLocale: 'es-US',
	relativeTime: {
		future: 'en %s',
		past: 'hace %s',
		s: function (number, withoutSuffix) {
			return withoutSuffix ? 'ahora' : 'unos segundos';
		},
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%d',
		M: '1me',
		MM: '%dme',
		y: '1a',
		yy: '%da',
	},
	longDateFormat: {
		LLL: 'D/MM/YYYY',
	},
	'Enter Unlock PIN': 'Ingresa tu PIN de desbloqueo',
	PIN: 'PIN',
	'Incorrect PIN. 2 tries remaining.': 'PIN incorrecto. Quedan 2 intentos.',
	'Incorrect PIN. 1 try remaining.': 'PIN incorrecto. Queda 1 intento.',
	'Unlock failed. Please sign in.': 'Falló el desbloqueo. Por favor, inicia sesión.',
	"Can't connect to Manifest servers!": 'Falló la conexión a los servidores de Manifest.',
	'An unknown error has occurred.': 'Un error desconocido ha ocurrido.',
	'Your session has expired.': 'Tu sesión ha expirado.',
	'Please sign in again.': 'Por favor, vuelve a iniciar sesión.',
	'Sign Out': 'Cerrar la Sesión',
	'Sign In': 'Iniciar Sesión',
	'Get Started': 'Empezar',
	'No credit check. No monthly fees.': 'No requiere verificación de crédito,\nni cargos mensuales.',
	SignOutWarning: ({ protectionName }) =>
		`Si se trata de tu dispositivo personal, puedes seguir conectado: tu cuenta está protegida con tu ${protectionName}.`,
	'Are you sure?': '¿Estás seguro?',
	'Face ID': 'Face ID',
	'Touch ID': 'Touch ID',
	fingerprint: 'huella dactilar',
	Checking: 'Cuenta de Débito',
	Savings: 'Cuenta de Ahorros',
	ProfilesDebit: ({ name }) => `Cuenta de Débito ${name}`,
	'Checking Balance': 'Saldo de la cuenta de débito',
	'Savings Balance': 'Saldo de la cuenta de ahorros',
	'Show Card Details': 'Detalles de tarjeta',
	'Turning on...': 'Activando...',
	'Card is on.': 'Activada',
	'Turning off...': 'Desactivando...',
	'Card is off.': 'Desactivada',
	'Free ATMs': 'Cajeros sin comisión',
	'Update My Address': 'Actualizar mi dirección',
	Theme: 'Modo de color',
	Language: 'Idioma',
	'Need help?': '¿Necesitas ayuda?',
	'support@manifestfinancial.com': 'support@manifestfinancial.com',
	'Error opening email app.': 'Error al abrir la aplicación de correo electrónico.',
	'Please email us at support@manifestfinancial.com':
		'Envíanos un correo electrónico a support@manifestfinancial.com',
	'Track your stats.': 'Sigue tus estadísticas.',
	'Spotify, YouTube, Instagram, TikTok, Facebook + more.': 'Spotify, YouTube, Instagram, TikTok, Facebook + más.',
	Cancel: `Cancelar`,
	Search: `Buscar`,
	Back: `Atrás`,
	Submit: `Enviar`,
	Continue: `Continuar`,
	Confirm: `Confirmar`,
	Skip: `Omitir`,
	Done: `Listo`,
	Business: `Negocio`,
	Personal: `Personal`,
	Profile: `Perfil`,
	Support: `Apoyo`,
	Pay: `Pagar`,
	Decline: `Rechazar`,
	'Street Address 1': 'Calle 1',
	'Street Address 2': 'Calle 2',
	State: 'Estado',
	Province: 'Provincia',
	Municipality: 'Municipio',
	'ZIP Code': 'Código Postal',
	'Postal Code': 'Código Postal',
	Notifications: `Notificaciones`,
	'Shake To Hide Money': 'Sacude el dispositivo para ocultar saldos',
	'Set Up Direct Deposit': 'Configurar Depósito Directo',
	'Learn More': 'Conoce más',
	'Add Savings': 'Agregar ahorros',
	To: 'Hacia',
	From: 'De',
	Amount: 'Monto',
	Description: 'Descripción',
	History: 'Historial',
	Friends: 'Amigos',
	Connect: 'Conectar',
	Connected: 'Conectados',
	Pending: 'Pendiente',
	Request: 'Pedir',
	Send: 'Enviar',
	Update: 'Actualizar',
	'Load More': 'Cargar Más',
	'Send Money': 'Enviar Dinero',
	'Request Money': 'Pedir Dinero',
	'Money Sent!': '¡Dinero enviado!',
	'Money Requested.': 'Dinero solicitado.',
	'Send Now': 'Enviar Ahora',
	'Request Now': 'Solicitar Ahora',
	'Oops!': '¡Uy!',
	'Free Allpoint ATMs': 'Cajeros Allpoint sin comisión',
	'Get Directions': 'Direcciones',
	'miles away': 'millas',
	'Enter transfer details.': 'Ingresa los detalles de la transferencia.',
	'Amount To Transfer': 'Cantidad a transferir',
	IsRequired: ({ field }) => `Se requiere ${field}.`,
	PleaseEnterYour: ({ field }) => `Introduce tu ${field}.`,
	'Select Account': 'Seleccionar Cuenta',
	'Funds available instantly.': 'Fondos disponibles al instante.',
	'"To" and "From" accounts must be different.': 'Selecciona cuentas distintas.',
	'Please select a "From" account.': 'Escoge una cuenta "De".',
	'Please select a "To" account.': 'Escoge una cuenta "Hacia".',
	ConfirmTransfer: ({ amount }) => `Transferir ${amount}.`,
	ConfirmRequest: ({ amount }) => `Solicitar ${amount}.`,
	ConfirmSend: ({ amount }) => `Enviar ${amount}.`,
	SendTo: ({ name }) => `Enviar a ${name}`,
	RequestFrom: ({ name }) => `Solicitar Pago de ${name}`,
	TheyRequestedBeforeAmount: `Pidieron `,
	TheyRequestedAfterAmount: ` por`,
	YouRequestedBeforeAmount: `Pedido `,
	YouRequestedAfterAmount: ` por`,
	'Amount Held Back To Savings': 'Cantidad retenida para ahorros',
	'Amount Rounded Up To Savings': 'Cantidad redondeada para ahorros',
	AddConnectionNoMoney: 'Deja que tus amigos escaneen este código para conectarse contigo o invita a tus contactos.',
	AddConnectionBeforeMoney:
		'Deja que tus amigos escaneen este código para conectarse contigo. invita a tus contactos y recibe',
	AddConnectionAfterMoney: 'cuando abran su cuenta.',
	'Round Up': 'Redondea',
	'Linked Accounts': 'Cuentas Vinculadas',
	'Link Bank': 'Agregar Banco',
	'No Linked Accounts': 'No hay cuentas vinculadas',
	'Keep your address up to date.': 'Manten tu dirección actualizada.',
	'Physical Address': 'Dirección postal',
	'Shipping Address': 'Dirección de envío',
	'My shipping address is different': 'Mi dirección de envío es diferente',
	'Invite Contacts': 'Invitar contactos',
	'Scan Code': 'Escanear',
	'My Settings': 'Configuración',
	'New Transaction Alerts': 'Alertas de transacciones nuevas',
	'Low Balance Alerts': 'Alertas de saldo bajo',
	'Notify me when my checking balance goes below':
		'Notificarme cuando el saldo de mi cuenta de débito sea inferior a',
	Privacy: 'Privacidad',
	'Shake to Hide Money': 'Sacude para ocultar tus saldos',
	'Shake your device to hide dollar amounts. (Try it here!)':
		'Sacude tu dispositivo para ocultar tu saldo. (¡Intentalo aquí!)',
	'Let Friends Find Me': 'Visible para mis amigos',
	'Contacts can use my phone number to invite me to connect.':
		'Mis contactos pueden usar mi número de teléfono para invitarme a conectar.',
	'Try Again': 'Inténtalo otra vez',
	'Free + Instant': 'Gratuito e Instantáneo',
	"What's it for?": '¿Para qué sirve?',
	IntroMoney: 'Dinero',
	IntroMoneyDetails: 'Separa tus finanzas de la música\nde tus finanzas personales.',
	IntroMusic: 'Música',
	IntroMusicDetails: 'Enlaza tus plataformas de\nstreaming + redes sociales.',
	IntroMore: 'Y Más',
	IntroMoreDetails: 'Pagos instantáneos a tus amigos, ahorros automatizados y más de 55 000 cajeros\nsin comisión.',
	Invite: 'Invitar',
	Country: 'País',
	City: 'Ciudad',
	Date: 'Fecha',
	Balance: 'Saldo',
	'Show All': 'Atrás',
	'More Countries Coming Soon!': '¡Proximament en más países!',
	'Create a Manifest login.': ({ appName }) => `Regístrate en ${appName}.`,
	'Email Address': 'Correo electrónico',
	'Mobile Number': 'Número de móvil',
	EnterValid: ({ field }) => `Por favor, introduce un ${field} válido.`,
	EnterYourFull: ({ field }) => `Por favor, introduce tu ${field} completo`,
	'Referral Code (optional)': 'Código de referencia (opcional)',
	'Referral Code (from invite link)': 'Código de referencia (vinculo de invitación)',
	'Please enter exactly 8 characters.': 'Ingresa exactamente 8 caracteres.',
	'Please enter your 10-digit number.': 'Ingresa tu número de 10 dígitos.',
	'Please enter at least 8 characters.': 'Ingresa al menos 8 caracteres.',
	TermsOfServicePre: 'Acepto los ',
	TermsOfServiceLink: 'términos de servicio',
	TermsOfServicePost: `.`,
	AchTermsOfServiceLink: 'términos de ACH',
	ConnectTermsLink: 'términos de Connect',
	'You must agree to the Manifest terms of service.': 'Debes aceptar los términos de servicio de Manifest.',
	'Error opening URL.': 'Error al abrir el URL.',
	'Your mobile number looks invalid. Please review it.':
		'Tu número de teléfono móvil parece inválido. Por favor revísalo.',
	'Social Insurance Number': 'Número de la Aseguranza Social',
	'Social Security Number': 'Número de Seguro Social',
	'Forgot your password?': '¿Olvidaste tus datos?',
	'Sign in to Manifest.': ({ appName }) => `Inicia sesión de ${appName}.`,
	"We'll email you a reset code.": 'Te enviaremos un código de restablecimiento por correo electrónico.',
	Password: 'Contraseña',
	'Your password has been reset!': '¡Tu contraseña ha sido restablecida!',
	'Incorrect email or password!': '¡Correo o contraseña incorrectos!',
	'Incorrect email or password reset code.': 'Correo electrónico o código de restablecimiento incorrectos.',
	'Your password reset code expired. Please try again.':
		'Tu código de restablecimiento ha vencido. Inténtalo de nuevo.',
	'Incorrect verification code. Please try again.': 'Código de verificación incorrecto. Inténtalo de nuevo.',
	'Your verification code expired. Please try again.': 'Tu código de verificación ha vencido. Inténtalo de nuevo.',
	Verify: 'Verificar',
	'Check your email.': 'Revisa tu correo electrónico.',
	'We emailed you a 6-digit code. Enter it below, along with your new password.':
		'Te hemos enviado un código de 6 dígitos por correo electrónico. Ingrésalo junto con tu contraseña nueva.',
	'6-Digit Code': 'Código de 6 dígitos',
	'Turn On Notifications': 'Activar las notificaciones',
	'Notifications Enabled!': '¡Notificaciones activadas!',
	'Notifications disabled. Turn on in device settings.':
		'Notificaciones desactivadas. Activar en los ajustes del dispositivo.',
	'Let’s talk business.': 'Hablemos de negocios.',
	'No fancy-pants business corporation Banky McBankerson monocle-wearing information required.':
		'No se requiere información comercial elegante.',
	BusinessIntroPre: 'En primer lugar, abriremos su cuenta comercial individual. Esto significa que ',
	BusinessIntroBold: 'solo',
	BusinessIntroPost:
		' necesitas tu información "personal" para abrir tu cuenta comercial de débito gratuita de Manifest.',
	'Have business partners?': '¿Tienes socios comerciales?',
	"Scan Your ID's Barcode": 'Escanea el códigos de barra de tu ID',
	'Scan your ID to prefill your personal info.': 'Escanea tu ID para capturar tu información personal.',
	'Or enter your information manually.': 'O ingresa tu información manualmente.',
	'Scan My ID': 'Escanear mi ID',
	'Verify and complete your info.': 'Verifica y completa tu información.',
	'Complete your info.': 'Acompleta tu información.',
	'Ship my card to a different address': 'Enviar mi tarjeta a una dirección diferente',
	'Why?': '¿Por qué?',
	'You must affirm that you work in the music business.': 'Debes confirmar que trabajas en la industria músical.',
	'You must agree to the Manifest account agreements.': 'Debes aceptar las condiciones de la cuenta Manifest.',
	DateEntryFormat: 'dd-mm-aaaa',
	DateScanFormat: 'DD-MM-YYYY',
	'First Name': 'Nombre',
	'Last Name': 'Apellido',
	Suffix: 'Sufijo',
	'Date of Birth': 'Fecha de nacimiento',
	PleaseFollowFormat: ({ format }) => `Usa este formato ${format}.`,
	'See errors above.': 'Revisa los errores indicados.',
	CardholderBefore: `Estoy de acuerdo con los `,
	CollabTermsLink: `términos de colaboración`,
	CheckingTermsLink: 'términos de la cuenta de débito',
	AgreementBetween: ', el ',
	CardholderLink: 'acuerdo del titular de la tarjeta',
	AgreementBetweenFinal: ', y las ',
	AchTermsLink: 'condiciones del servicio de ACH',
	FrontMeTermsLink: 'condiciones de Front Me',
	CardholderAfter: '.',
	Citizenship: 'Ciudadanía',
	Agreements: 'Acuerdos',
	ApplicationSuccess: ({ name }) => `Tu solicitud ha sido aprobada. ¡Bien hecho ${name}!`,
	'Your account is being opened, and your card is in the mail!':
		'Tu cuenta esta siendo procesada y tu tarjeta va en camino.',
	'Go Back': `Atrás`,
	'Re-Enter Info': 'Reingresa tu información',
	'We couldn’t verify your info — please double check it.':
		'No hemos podido verificar tu información - por favor, revisa tus datos.',
	'If you’ve recently moved, try using your previous address for your primary address, and your new address as your shipping address.':
		'Si has cambiado tu domicilio recientemente, intenta utilizar tu dirección anterior como dirección principal y tu nueva dirección como dirección de envío.',
	'Contact Support': 'Servicio de Asistencia',
	'We were unable to verify your personal info.': 'No hemos podido verificar tu información personal.',
	'Please reach out to us so we can manually confirm your identity. Please don’t include personal information in your email.':
		'Ponte en contacto con nosotros para confirmar manualmente tu identidad. Por favor, no incluyas información personal en tu correo electrónico.',
	'Please enter a 4-digit PIN.': 'Por favor, introduce un PIN de 4 dígitos.',
	'4-Digit PIN': 'PIN de 4 Dígitos',
	'Please enter the 6-digit code we texted you.':
		'Ingresa el código de 6 dígitos que te hemos enviado por mensaje de texto.',
	'Set an unlock PIN': 'Establece tu PIN de desbloqueo',
	'Unlock next time with': 'Desbloquea la próxima vez con',
	'We texted you a 6-digit code.': 'Te enviamos un código de\n6 dígitos.',
	'Manifest uses security codes to protect accounts.': ({ appName }) =>
		`${appName} utiliza códigos de seguridad para proteger las cuentas.`,
	"Didn't receive a code?": '¿No has recibido el código?',
	'just now': 'ahora mismo',
	'Transfer History': 'Historial de transferencias',
	'External Transfer History': 'Historial de transferencias externas',
	Canceled: 'Cancelada',
	Sent: 'Enviada',
	Initiated: 'Iniciada',
	Returned: 'Devuelta',
	Processing: 'Procesando',
	Error: 'Error',
	'Address updated.': 'Dirección actualizada.',
	'Wait!': '¡Espera!',
	'If you think you misplaced your card, just turn your card off. This will help protect you until you find it and turn it back on.':
		'Si no estas seguro si perdiste tu tarjeta, apágala.  Así no puede ser utilizada y si la encuentras, la vuelves a prender.',
	'Turn My Card Off': 'Apagar mi tarjeta',
	'Get A Replacement': 'Obtener un reemplazo',
	'Please enter your mobile number to retry verification.':
		'Por favor, introduce tu número de móvil para reintentar la verificación.',
	'Send Another Code': 'Necesito otro código',
	'Funds available in 2-3 days. Track external transfers in the "History" section above.':
		'Los fondos estarán disponibles en 3 o 4 días. Sigue las transferencias externas en el Historial.',
	Select: 'Selecciona',
	SavingsTermsBefore: `Acepto las `,
	SavingsTermsLink: 'condiciones de la\ncuenta de ahorros',
	SavingsTermsAfter: '.',
	// 'Add Savings': 'Añadir cuenta de ahorros',
	'Free account. Instant open.': 'Cuenta gratuita. Apertura instantánea.',
	"You're two taps away from a smart savings account.": 'Estás a dos pasos de una cuenta de ahorros inteligente.',
	'You must agree to the savings terms.': 'Debes aceptar las condiciones de la cuenta de ahorros.',
	'Opening account— This will only take around 20 seconds.': 'Abrir una cuenta—\nTe llevará unos 20 segundos.',
	'Deposit Money': 'Depositar dinero',
	'To sign up for direct deposit:': 'Para suscribirse en depósito directo:',
	'Get all your music money in one place.': 'Tus finanzas músicales en un solo lugar.',
	'When companies pay you, make sure it goes right into this account. Simply tap below to make that happen.':
		'Asegúrate que tus ganancias vayan directamente a esta cuenta. Pulsa el botón de abajo y listo.',
	'I make money with music.': 'Gano dinero haciendo música.',
	'We allow you to connect with your partners, but the first step is to open an individual business account.':
		'Te permitimos conectarte con tus socios, pero el primer paso es abrir una cuenta individual de empresarial.',
	'Chat with support': 'Chat con soporte',
	'Open website': 'Abrir el sitio web',
	'Check our Help Center': 'Consulte nuestro centro de asistencia',
	'Email support': 'Asistencia',
	'Email Support': 'Asistencia',
	'Error opening web browser': 'Error al abrir el navegador web',
	Close: 'Cerrar',
	Download: 'Descargar',
	Edit: 'Editar',
	'What should we call you?': '¿Cómo deberíamos llamarte?',
	Nickname: 'Apodo',
	GotIt: ({ nickname }) => `Listo, ${nickname}.`,
	'Your Link': 'Tu enlace',
	'Share My Link': 'Compartir mi enlace',
	'Legal Notices': 'Avisos legales',
	'No Documents': 'No hay documentos',
	expected: 'se espera para',
	'expected today': 'se espera para hoy',
	overdue: 'atrasado',
	AchAuth:
		'Autorizo al Banco Piermont a cargar/abonar electrónicamente mis cuentas en los próximos 2-3 días laborables. Estas transacciones cumplen con todas las leyes aplicables.',
	'Deposits submitted within the next': 'Depósitos presentados en los próximos',
	OnDate: ({ date }) => `el ${date}`,
	today: 'hoy',
	HourAbbreviation: 'h',
	HourAbbreviationPlural: 'h',
	MinuteAbbreviation: 'min',
	MinuteAbbreviationPlural: 'min',
	'New Password': 'Contraseña nueva',
	'Current Password': 'Contraseña actual',
	'Your password has been changed!': 'Tu contraseña ha sido cambiada!',
	'Change Password': `Cambiar contraseña`,
	Title: 'Título',
	Add: 'Agregar',
	'Loading...': 'Cargando...',

	//new
	IntroHeadline: ({ enterpriseName, useCase, appName }) => {
		return (
			{
				generic: `${enterpriseName} está asociado con ${appName}.`,
				payout: `${enterpriseName} utiliza ${appName} para pagarle.`,
				advance: `${enterpriseName} utiliza ${appName} para adelantarte dinero.`,
				loan: `${enterpriseName} utiliza ${appName} para prestarte dinero.`,
				whitelabel: `${appName} es impulsado por Manifest.`,
			}[useCase] || `${enterpriseName} está asociado con ${appName}.`
		);
	},
	IntroDescription: `Manifest ofrece servicios bancarios gratuitos para los creadores.`,
	ConnectHeadline: ({ enterpriseName }) => `Conecta con ${enterpriseName}.`,
	PermissionsBoxHeadline: ({ enterpriseName }) => `${enterpriseName} podrá:`,
	OptionsHeadline: ({ useCase, offerAchOption, offerPaypalOption, enterpriseName }) => {
		const numberOfOptions = [true, offerPaypalOption, offerAchOption].filter(Boolean).length;
		if (numberOfOptions > 1) {
			return (
				{
					generic: `Hay ${numberOfOptions} formas de conectarse.`,
					payout: `Hay ${numberOfOptions} formas de cobrar.`,
					advance: `Hay ${numberOfOptions} formas de obtener el anticipo.`,
					loan: `Hay ${numberOfOptions} maneras de obtener su dinero.`,
				}[useCase] || `Hay ${numberOfOptions} formas de conectarse.`
			);
		} else {
			return (
				{
					generic: `Conectar con ${enterpriseName}.`,
					payout: `Conéctate para que te paguen.`,
					advance: `Conéctese para obtener su anticipo.`,
					loan: `Conéctate para conseguir tu dinero.`,
				}[useCase] || `Conectar con ${enterpriseName}.`
			);
		}
	},
	'Sign out?': '¿Cerrar la sesión?',
	'There are 2 ways to get paid.': 'Hay dos formas de cobrar.',
	ManifestOptionHeadline: ({ appName, enterpriseName }) =>
		`Abre una cuenta gratuita de ${appName} y obtén acceso instantáneo a tu dinero desde ${enterpriseName}.`,
	'Business debit and savings for creators.': 'Débito empresarial y ahorro para los creadores.',
	'Automate tax savings.': 'Automatizar el ahorro fiscal.',
	'Collect money with invoices.': 'Cobrar dinero con facturas.',
	'Apps for iOS + Android.': 'Aplicaciones para iOS + Android.',
	'Open in 1 minute.': 'Abre en 1 minuto.',
	'Already have Manifest?': ({ appName }) => `¿Ya tiene ${appName}?`,
	'Sign in.': 'Iniciar sesión.',
	AchOptionHeadline: `Utilice un banco diferente y reciba su dinero en 2-3 días laborables.`,
	PaypalOptionHeadline: `Utilice PayPal o Venmo para recibir su dinero en 7 días.`,
	SecondChanceAch: ({ enterpriseName }) =>
		`No te preocupes, ${enterpriseName} puede pagarle utilizando un método diferente.`,
	'Bank Info': 'Información Bancaria',
	'Personal Info': 'Información Personal',
	PermissionDescription: ({ name, durationInDays = 0, appName }) => {
		if (durationInDays > 0) {
			let durationLabel = `durante ${durationInDays} días.`;
			if (durationInDays === 365) {
				durationLabel = `durante 1 año.`;
			}
			return (
				{
					'payment.credit': `Pagar su cuenta de ${appName} y ver los nombres y correos electrónicos asociados a su cuenta ${durationLabel}`,
					'payment.debit':
						'Cargar su cuenta de ${appName}. (No te preocupes, siempre te pediremos permiso antes.)',
					personal_info: `Ver su información personal ${durationLabel}`,
					balance: `Ver los saldos de tu cuenta de ${appName} ${durationLabel}`,
					activity: `Ver la actividad de tu cuenta de ${appName} ${durationLabel}`,
					all_data: `Pagar su cuenta de ${appName} y ver los saldos de su cuenta de ${appName}, la actividad de tu cuenta y su información personal ${durationLabel}`,
				}[name] || name
			);
		} else {
			return (
				{
					'payment.credit': `Pagar su cuenta de ${appName} y ver los nombres y correos electrónicos asociados a su cuenta.`,
					'payment.debit':
						'Cargar su cuenta de ${appName}. (No te preocupes, siempre te pediremos permiso antes.)',
					personal_info: `Ver su información personal.`,
					balance: `Ver los saldos de tu cuenta de ${appName}.`,
					activity: `Ver la actividad de tu cuenta de ${appName}.`,
					all_data: `Pagar su cuenta de ${appName} y ver los saldos de su cuenta de ${appName}, la actividad de tu cuenta y su información personal.`,
				}[name] || name
			);
		}
	},
	BackTo: ({ enterpriseName }) => (enterpriseName ? `Volver a ${enterpriseName}` : `Volver`),
	'All done!': '¡Todo listo!',
	ConnectionComplete: ({ enterpriseName }) => `Ahora puedes volver a ${enterpriseName}.`,
	'Verification Answer': 'Respuesta de verificación',
	'Name On Account': 'Nombre en la cuenta',
	'Account Number': 'Número de cuenta',
	'Verify Account Number': 'Verificar el número de cuenta',
	'Routing Number': 'Número de ruta',
	'Please enter your 9-digit routing number.': 'Por favor, introduzca su número de ruta de 9 dígitos.',
	'Your account numbers do not match.': 'Sus números de cuenta no coinciden.',
	'Please check your routing number.': 'Por favor, compruebe su número de ruta.',
	'Your link has expired. Please start over.': 'Su enlace ha caducado. Por favor, vuelva a empezar.',
	'Your invite has expired.': 'Su invitación ha caducado.',
	'Start Over': 'Volver a empezar',
	'You are currently connected to:': 'Actualmente estás conectado a:',
	'Enter Routing/Account Info': 'Introducir información de ruta/cuenta',
	'Link PayPal/Venmo': 'Vincular PayPal/Venmo',
	'Sign into Manifest to modify your connection.': 'Inicia sesión en Manifest para modificar tu conexión.',
	'Edit your connection.': 'Edita tu conexión.',
	'Upgrade your connection.': 'Actualice su conexión.',
	'Enter your banking details.': 'Introduzca sus datos bancarios.',
	'Edit Settings': 'Editar ajustes',
	CurrentAchBanner: ({ enterpriseName }) => `Conectado a ${enterpriseName} con`,
	CurrentManifestBanner: ({ enterpriseName }) => `Conectado a ${enterpriseName} con`,
	NewPermissionsBanner: ({ enterpriseName }) => `${enterpriseName} solicita nuevos permisos.`,
	UpgradeToManifestBannerHeadline: ({ enterpriseName }) => `${enterpriseName} necesita que te actualices.`,
	UpgradeToManifestBannerBody: `Obtenga una cuenta Manifest para mejorar su conexión.`,
	CheckingTypeAch: 'Cuenta Corriente',
	DisconnectHeadline: ({ enterpriseName }) => `Desconéctate de ${enterpriseName}.`,
	DisconnectBodyAch: ({ enterpriseName }) =>
		`Al desconectarse, eliminará la capacidad de ${enterpriseName} de pagar a su cuenta bancaria mediante ACH.`,
	DisconnectBodyManifest: ({ enterpriseName }) =>
		`Al desconectar, eliminarás la conexión de ${enterpriseName} con tu cuenta de Manifest. Tu cuenta de Manifest seguirá siendo totalmente funcional y estará disponible en la app de Manifest.`,
	'Security check.': 'Control de seguridad.',
	Unlock: 'Desbloquear',
	'Error 404 — Page not found.': 'Error 404 - Página no encontrada.',
	'SSL Encrypted': 'Encriptada SSL',
	'Connect your creator profiles so we can verify you.':
		'Conecta tus perfiles de creador para que podamos verificarte.',
	'Connect Profiles': 'Conectar Perfiles',
	'Manifest is currently invite only — but you can skip the line. Link your social profiles to see if you qualify for an automatic invite.':
		'Actualmente, Manifest sólo cuenta con invitaciones, pero puedes saltarte la cola. Vincula tus perfiles sociales para ver si puedes recibir una invitación automática.',
	"You're in! Now, complete your info.": 'Ya estás dentro. Ahora, completa tu información.',
	"Looks like you didn't qualify for an automatic invite. Improve your chances of getting in by connecting more profiles!":
		'Parece que no has conseguido una invitación automática. ¡Aumenta tus posibilidades de entrar conectando más perfiles!',
	'Connect More Profiles': 'Conectar más perfiles',
	NoPhylloSecondChanceAch: ({ enterpriseName }) =>
		`Alternativamente, ${enterpriseName} puede pagarle utilizando un método diferente.`,
	'Enter Other Bank Info': 'Introducir otros datos bancarios',
	'You can close this window.': 'Puede cerrar esta ventana.',
	'Must be valid email or 10-digit phone number.':
		'Debe ser un correo electrónico válido o un número de teléfono de 10 dígitos.',
	'Enter your Paypal or Venmo info.': 'Introduzca su información de Paypal o Venmo.',
	'Email Address or Mobile Number': 'Dirección de correo electrónico o número de móvil',
	'Use Another Method': 'Utilizar otro método',
	'Have a Manifest account?': '¿Tienes una cuenta de Manifest?',
	'Scheduled Payouts': 'Pagos programados',
	'Payout Details': 'Detalles del pago',
	FrontMeHeadline: 'Anticipe su pago con Front Me®',
	'Net Advance Amount': 'Importe neto del anticipo',
	'Front Me® Details': 'Detalles de Front Me',
	'Advance Date': 'Fecha de anticipación',
	'Scheduled For': 'Programado para',
	Terms: 'Condiciones',
	'Offer Accepted At': 'Oferta aceptada en',
	'Scheduled Date': 'Fecha prevista',
	Destination: 'Destino',
	DaysUntilSuffix: ({ daysUntil }) => ` - en ${daysUntil} ${daysUntil === 1 ? 'día' : 'días'}`,
	'Sends On': 'Envía el',
	'Payout Details': 'Detalles del pago',
	'Payout Amount': 'Importe del pago',
	Receive: 'Reciba',
	Fee: 'Tarifa',
	'Offer accepted on': 'Oferta aceptada el',
	'Front Me® Available': 'Front Me® disponible',
	'Confirm your Front Me.': 'Confirme su Front Me.',
	AppLinkHeading: ({ appName, nickname }) =>
		`¡Buen trabajo${
			nickname ? `, ${nickname}` : ''
		}! Escanea este código para\ndescargar la app ${appName} e iniciar sesión.`,
	UserAddendumLink: `términos de la aplicación`,
	'Unfortunately, at this time, we are not able to open a Manifest account for you. Thank you!':
		'Lamentablemente, en este momento no podemos abrir una cuenta de Manifest para ti. ¡Gracias!',
	"We're going to need a little more information to verify your identity.": `Necesitamos un poco más de información para verificar tu identidad.`,
	'Sorry for the inconvenience, but we need to get some supporting documents from you to verify your identity.': `Lo siento por las molestias, pero necesitamos obtener algunos documentos de apoyo de usted para verificar su identidad.`,
	'There was an issue verifying your personal info.': `Hubo un problema al verificar su información personal.`,
	'Please contact support@manifestfinancial.com for assistance.': `Por favor, póngase en contacto con support@manifestfinancial.com para obtener ayuda.`,
};

export default create((set, get) => ({
	language: null,
	languageDisplay: null,
	contentfulLocale: null,
	setLanguage: language => {
		const dictionary = { es, en }[language] || en;
		const languageDisplay = dictionary.dictionaryName;
		const contentfulLocale = dictionary.contentfulLocale;

		set({ language, languageDisplay, contentfulLocale, dictionary });
		moment.locale(language);
		moment.updateLocale(language, {
			relativeTime: dictionary.relativeTime,
			longDateFormat: dictionary.longDateFormat,
		});
	},
	dictionary: null,
	t: (key, values) => {
		let { dictionary } = get();
		dictionary = dictionary || en;
		const word = dictionary[key];
		if (typeof word === 'function') {
			return word(values);
		} else if (word) {
			return word;
		} else if (en[word]) {
			return en[word];
		} else {
			return key;
		}
	},
}));
