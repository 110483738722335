import backButton from '../assets/backButton.svg';
import closeButton from '../assets/closeButton.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores';

export default function BackButton({ logout = false, close = false, className, onClick: externalOnClick, style, to }) {
	const { confirmLogout } = useAuthStore();
	const navigate = useNavigate();

	const onClick = () => {
		if (to) {
			navigate(to);
		} else if (externalOnClick) {
			externalOnClick();
		} else if (logout) {
			confirmLogout();
		} else {
			navigate(-1);
		}
	};

	return (
		<div className={`back-button ${className}`} style={style} onClick={onClick}>
			<img src={close ? closeButton : backButton} alt="Go back" />
		</div>
	);
}
