import create from 'zustand';
import API from '../util/API';

export default create((set, get) => ({
	enterpriseName: null,
	enterpriseAvatar: null,
	permissions: [],
	useCase: 'payout',
	error: false,
	requestingNewPermissions: false,
	loadSession: async token => {
		let session;

		try {
			session = await API.get(`/banking/connect/invite/${token}`);
		} catch (e) {
			set({ error: true });
			return;
		}

		const {
			connectInvite: { expiresAt, verificationPrompt },
		} = session;

		if (new Date() > new Date(expiresAt)) {
			set({ error: true });
			return;
		}

		set({ verificationPrompt });
	},
	verifyInviteAndCreateLinkToken: async (inviteToken, verificationAnswer) => {
		return await API.post(`/banking/connect/invite/${inviteToken}`, { verificationAnswer });
	},
}));
