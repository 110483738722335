import { useState, useEffect } from 'react';
import { useLanguageStore, useConnectStore } from '../stores';
import manifestLogo from '../assets/manifestLogo.svg';
import { Button, BackButton, SandboxChip, SecurityChip } from '../components';
import { useParams, Link } from 'react-router-dom';
import useQueryString from 'use-query-string';
import { useNavigate } from 'react-router-dom';

export default function Intro() {
	const { token } = useParams();
	const { t } = useLanguageStore();
	const [{ redirect }] = useQueryString(window.location);
	const navigate = useNavigate();
	const {
		hasLoadedSession,
		enterpriseName,
		enterpriseAvatar,
		useCase,
		accountConnection,
		returnToEnterprise,
		canReturnToEnterprise,
		isEmbedded,
		requestingNewPermissions,
		setToken,
		appName,
	} = useConnectStore();

	useEffect(() => {
		setToken(token);
	}, [token]);

	useEffect(() => {
		if (hasLoadedSession && redirect) {
			navigate(`../${redirect}`, { replace: true });
		}
	}, [hasLoadedSession]);

	if (!hasLoadedSession) return null;

	const activeConnection = accountConnection?.status === 'active';

	let buttonLabel = null;
	let buttonDestination = null;
	let secondaryButtonLabel = null;
	let secondaryButtonDestination = null;
	if (accountConnection.status !== 'active') {
		buttonLabel = 'Continue';
		buttonDestination = '../options';
	} else if (accountConnection.type === 'direct') {
		if (requestingNewPermissions) {
			buttonLabel = 'Sign In';
			buttonDestination = '../sign-in';
			secondaryButtonLabel = 'Edit Settings';
			secondaryButtonDestination = '../options';
		} else {
			buttonLabel = 'Edit Settings';
			buttonDestination = '../options';
		}
	} else if (accountConnection.type === 'ach' || accountConnection.type === 'paypal') {
		if (requestingNewPermissions) {
			buttonLabel = 'Upgrade';
			buttonDestination = '../options?upgrade=1';
			secondaryButtonLabel = 'Edit Settings';
			secondaryButtonDestination = '../options';
		} else {
			buttonLabel = 'Edit Settings';
			buttonDestination = '../options';
		}
	}

	return (
		<div className="page-container centered">
			<div className="page">
				{accountConnection.type === 'ach' && !requestingNewPermissions && (
					<div className="banner existing-connection">
						<span>{t('CurrentAchBanner', { enterpriseName })}</span>
						<span className="headline">{accountConnection.destination}</span>
					</div>
				)}
				{accountConnection.type === 'ach' && requestingNewPermissions && (
					<div className="banner new-permissions">
						<span className="headline">{t('UpgradeToManifestBannerHeadline', { enterpriseName })}</span>
						<span>{t('UpgradeToManifestBannerBody', { enterpriseName })}</span>
					</div>
				)}
				{accountConnection.type === 'direct' && !requestingNewPermissions && (
					<div className="banner existing-connection">
						<span>{t('CurrentManifestBanner', { enterpriseName })}</span>
						<span className="headline">{accountConnection.destination}</span>
					</div>
				)}
				{accountConnection.type === 'direct' && requestingNewPermissions && (
					<div className="banner new-permissions">
						<span className="headline">{t('NewPermissionsBanner', { enterpriseName })}</span>
						<span>{t(`Sign in and review the request.`)}</span>
					</div>
				)}
				{accountConnection.type === 'paypal' && !requestingNewPermissions && (
					<div className="banner existing-connection">
						<span>{t('CurrentManifestBanner', { enterpriseName })}</span>
						<span className="headline">{accountConnection.destination}</span>
					</div>
				)}
				{accountConnection.type === 'paypal' && requestingNewPermissions && (
					<div className="banner new-permissions">
						<span className="headline">{t('NewPermissionsBanner', { enterpriseName })}</span>
						<span>{t(`Sign in and review the request.`)}</span>
					</div>
				)}

				{canReturnToEnterprise() && (
					<BackButton
						onClick={returnToEnterprise}
						close={isEmbedded()}
						className={`${activeConnection ? 'showing-banner' : ''}`}
					/>
				)}
				<div className={`right-nav-button ${activeConnection ? 'showing-banner' : ''}`}>
					<SandboxChip />
					<SecurityChip />
					<Link to={`/en/intro/${token}`}>EN</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
					<Link to={`/es/intro/${token}`}>ES</Link>
				</div>
				<div className="logo-circle-holder">
					<div className="logo-circle">
						<img src={enterpriseAvatar} alt={enterpriseName} />
					</div>
					<div className="logo-circle">
						<img src={manifestLogo} alt="Manifest" />
					</div>
				</div>
				<h1>{t('IntroHeadline', { enterpriseName, useCase, appName })}</h1>
				<p>{t('IntroDescription')}</p>
				<Button to={buttonDestination} style={{ width: 240, marginTop: 60 }}>
					{t(buttonLabel)}
				</Button>
				{secondaryButtonDestination && (
					<Button small theme="subtle" to={secondaryButtonDestination} style={{ width: 240, marginTop: 10 }}>
						{t(secondaryButtonLabel)}
					</Button>
				)}
			</div>
		</div>
	);
}
